import axios from '@/core/axios';

import { Grade, GradeDevice, GradeReportFile } from './grades.types';

export class GradesService {
  list(serial_no: string): Promise<Grade[]> {
    if (!serial_no) throw new Error('Missing serial_no in get');
    return axios.get<Grade[]>(`/grades/list/${serial_no}`).then(resp => resp?.data);
  }

  get(grade_id: number): Promise<Grade> {
    if (!grade_id) throw new Error('Missing grade_id in getGrade');
    return axios.get<Grade>(`/grades/${grade_id}`).then(resp => resp?.data);
  }

  getGradeFields(model_selected: string): Promise<Grade> {
    return axios.post<Grade>(`/grades/grade-fields`, { model_selected }).then(resp => resp?.data);
  }

  gradeTask(grading: Grade): Promise<Grade> {
    return axios.post<Grade>(`/grades/tasks`, { grading }).then(resp => resp?.data);
  }

  create(grading: Grade): Promise<Grade> {
    return axios.post<Grade>(`/grades`, { grading }).then(resp => resp?.data);
  }

  update(grade_id: number, payload: { grading: Grade; step: string }): Promise<GradeDevice> {
    if (!grade_id) throw new Error('Missing grade_id in update');
    const { grading, step } = payload;
    return axios.put<GradeDevice>(`/grades/${grade_id}/${step}`, { grading }).then(resp => resp?.data);
  }

  getReport(grade_id: number): Promise<GradeReportFile> {
    return axios.get<GradeReportFile>(`/grades/reports/${grade_id}`).then(resp => resp?.data);
  }

  addGradePhotos(grade_id: number, gradePhoto: Record<string, File>): Promise<Grade> {
    if (!grade_id) throw new Error('Missing grade id in addGradePhotos');
    const formData = Object.entries(gradePhoto).reduce((prev, curr) => {
      prev.append(`grade[${curr[0]}]`, curr[1]);
      return prev;
    }, new FormData());

    return axios
      .put<Grade>(`grades/photo/${grade_id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  deleteGradePhotos(grade_id: number, gradingPhotos: (string | File)[]): Promise<Grade> {
    if (!grade_id) throw new Error('Missing grade id in deleteGradePhotos');
    return axios.post<Grade>(`grades/delete_photos/${grade_id}`, { gradingPhotos }).then(resp => resp?.data);
  }

  updateOkamacGrade(grade_id: number, grade: string): Promise<Grade> {
    return axios.post<Grade>(`/grades/update-okamac-grade/${grade_id}`, { grade }).then(resp => resp?.data);
  }
  setGrade(grade_id: number): Promise<Grade> {
    if (!grade_id) throw new Error('Missing grade_id in setGrade');
    return axios.get<Grade>(`/grades/set_grade/${grade_id}`).then(resp => resp?.data);
  }
}
export const gradesService = new GradesService();
