/* eslint-disable prettier/prettier */
import { get, isNaN, isNil } from 'lodash';

import { Attribute, Collection, CollectionName } from '@/services/collections/collections.types';
import { Cage, CageCarrierLabel } from '@/services/cages/cages.types';
import { datesService } from '@/services/dates/dates.service';
import { Device } from '@/services/devices/devices.types';
import { dicoLabelsService } from '@/services/dico-labels/dico-labels.service';
import { MarketplaceSku } from '@/services/marketplace-skus/marketplace-skus.types';
import { OrderLine } from '@/services/order-lines/order-lines.types';
import { OrderReturn } from '@/services/order-returns/order-returns.types';
import { Order } from '@/services/orders/orders.types';
import { Picking } from '@/services/pickings/pickings.types';
import { ProductionOrder } from '@/services/production-orders/production-orders.types';
import { Consumable } from '../consumables/consumables.types';
import { GlobalSettings } from '../global-settings/global-settings.types';
import { MacStock } from '../mac-stocks/mac-stocks.types';
import { MatchError } from '../match-errors/match-errors.types';
import { FilterOptions } from '../options/options.types';
import { PurchasableCategory, PurchaseQuery } from '../purchase-queries/purchase-queries.types';
import { Purchase, PurchaseFiles, PurchaseItem, PurcheStatus } from '../purchases/purchases.types';
import { Spare, SpareType } from '../spares/spares.types';
import { TableSortingKey } from './tables.types';
import { Commission } from '../commissions/commissions.types';
import { Part } from '../parts/parts.types';
import { FileStorageAttachments } from '../file-storage-attachemnts/file-storage-attachments.types';
import { UkInvoice } from '../ukManagement/ukInvoices/ukInvoices.types';
import { UkTransfer } from '../ukManagement/ukTransfer/ukTransfer.types';
import { Supplier } from '../suppliers/suppliers.types';
import { SupplierGrade } from '../suppliers-grade/suppliers-grade.types';

class TablesService {
  listTableColumns<T extends CollectionName>(
    table: T,
    settings: GlobalSettings,
    filters: FilterOptions<T>
  ): Attribute<Collection<T>>[] {
    let list: Attribute<any>[];
    switch (table) {
      case 'commissions':
        list = this.listCommissionColumns(settings);
        break;
      case 'consumables':
        list = this.listConsumableColumns();
        break;
      case 'devices':
        list = this.listDeviceColumns(settings);
        break;
      case 'dispatch-cages':
        list = this.listDispatchCagesColumns();
        break;
      case 'mac-stocks':
        list = this.listMacStocksColumns(settings);
        break;
      case 'marketplace-skus':
        list = this.listMarketplaceSkuColumns(settings);
        break;
      case 'match-errors':
        list = this.listMatchErrorColumns(settings);
        break;
      case 'orders':
        list = this.listOrderColumns(settings);
        break;
      case 'order-lines':
        list = this.listOrderLineColumns(settings);
        break;
      case 'order-returns':
        list = this.listOrderReturnColumns(settings, filters);
        break;
      case 'pickings':
        list = this.listPickingColumns(settings);
        break;
      case 'parts':
        list = this.listsPartColumns();
        break;
      case 'purchases': {
        const statut = (filters as FilterOptions<'purchases'>)?.purchase_status as PurcheStatus;
        list = this.listPurchasesColumns(settings, statut);
        break;
      }
      case 'production-orders':
        list = this.listProductionOrderColumns(settings);
        break;
      case 'purchase-queries': {
        const category = (filters as FilterOptions<'purchase-queries'>)?.purchasable_category as PurchasableCategory;
        list = this.listPurchaseQueriesColumns(category);
        break;
      }
      case 'suppliers':
        list = this.listSupplierColumns(settings);
        break;
      case 'spares': {
        const spareType = (filters as FilterOptions<'spares'>)?.spare_type as SpareType;
        list = this.listSpareColumns(spareType);
        break;
      }
      case 'special-process-priority': {
        list = this.listSpecialProcessPriority(settings);
        break;
      }
      case 'uk-invoices': {
        list = this.listUkInvoicesColumns(settings);
        break;
      }
      case 'uk-transfer': {
        list = this.listUkTransferColums(settings);
        break;
      }
      default:
        throw new Error('listTableColumns unknown');
    }
    return list;
  }

  listModalTableColumns<T extends CollectionName>(table: T): Attribute<Collection<T>>[] {
    let list: Attribute<any>[];

    if (table === 'dispatch-cages') list = this.listCageCarrierModalColumns();

    return list;
  }

  supplierGradeTable(): Attribute<SupplierGrade>[] {
    return [
      {
        id: 'name',
        label: dicoLabelsService.getCollectionLabel('name'),
        accessor: supplierGrade => supplierGrade.name || '-'
      },
      {
        id: 'discount',
        label: dicoLabelsService.getCollectionLabel('discount'),
        accessor: supplierGrade => supplierGrade.discount || '-'
      },
      {
        id: 'supplier_grade_parent',
        label: dicoLabelsService.getCollectionLabel('supplier_grade_parent'),
        accessor: supplierGrade => supplierGrade.supplier_grade_parent?.name || '-'
      },
      {
        id: 'model',
        label: dicoLabelsService.getCollectionLabel('model', 'short'),
        accessor: supplierGrade => supplierGrade.model || '-'
      },
      {
        id: 'purchase_reference',
        label: dicoLabelsService.getCollectionLabel('purchase_reference'),
        accessor: supplierGrade => supplierGrade.purchase_reference || '-'
      }
    ];
  }

  purchaseItemsTable(settings: GlobalSettings, columnTable: string[]): Attribute<PurchaseItem>[] {
    const screens = Object.fromEntries(settings?.screens || []);
    const years = Object.fromEntries(settings?.years || []);
    const procs = Object.fromEntries(settings?.procs || []);

    const purchase_items_table = [];
    if (columnTable.includes('model'))
      purchase_items_table.push({
        id: 'model',
        label: dicoLabelsService.getCollectionLabel('model', 'short'),
        accessor: item => item.code?.substring(0, 2)
      });
    if (columnTable.includes('quantity'))
      purchase_items_table.push({
        id: 'quantity',
        label: dicoLabelsService.getCollectionLabel('quantity', 'short'),
        accessor: item => item.quantity || '-'
      });
    if (columnTable.includes('price_avg'))
      purchase_items_table.push({
        id: 'price_avg',
        label: dicoLabelsService.getCollectionLabel('price_avg', 'long'),
        accessor: item => `${item.price_avg || '-'} ${dicoLabelsService.getUnit('money')}`
      });
    if (columnTable.includes('supplier_grade'))
      purchase_items_table.push({
        id: 'supplier_grade',
        label: dicoLabelsService.getCollectionLabel('grade'),
        accessor: item => {
          return item.supplier_grade;
        }
      });
    if (columnTable.includes('tax_scheme'))
      purchase_items_table.push({
        id: 'tax_scheme',
        label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short'),
        accessor: item => {
          return item.tax_scheme;
        }
      });
    if (columnTable.includes('purchase_country'))
      purchase_items_table.push({
        id: 'purchase_country',
        label: dicoLabelsService.getCollectionLabel('country'),
        accessor: item => {
          return item.purchase_country;
        }
      });
    if (columnTable.includes('year'))
      purchase_items_table.push({
        id: 'year',
        label: dicoLabelsService.getCollectionLabel('year', 'short'),
        accessor: item => years[item.code?.substring(4, 7)]?.display_name || '-'
      });
    if (columnTable.includes('size'))
      purchase_items_table.push({
        id: 'size',
        label: dicoLabelsService.getCollectionLabel('taille', 'short'),
        accessor: item => `${screens[item.code?.substring(2, 4)] || '-'} ${dicoLabelsService.getUnit('screen_size')}`
      });
    if (columnTable.includes('proc'))
      purchase_items_table.push({
        id: 'proc',
        label: dicoLabelsService.getCollectionLabel('proc', 'short'),
        accessor: item => {
          return procs[item.code?.substring(7, 10)] || '-';
        }
      });
    if (columnTable.includes('freq'))
      purchase_items_table.push({
        id: 'freq',
        label: dicoLabelsService.getCollectionLabel('frequence', 'short'),
        accessor: item => {
          const freqString = item.code?.substring(10, 13);
          return `${freqString ? parseFloat(freqString) / 100 : '-'} ${dicoLabelsService.getUnit('frequency')}`;
        }
      });
    return purchase_items_table;
  }

  listSortingKeys(table: CollectionName): TableSortingKey[] {
    let list: TableSortingKey[];

    switch (table) {
      case 'commissions':
        list = this.listCommissionsKeys();
        break;
      case 'consumables':
        list = this.listConsumableKeys();
        break;
      case 'devices':
        list = this.listDeviceKeys();
        break;
      case 'dispatch-cages':
        list = this.listDispatchCagesKeys();
        break;
      case 'mac-stocks':
        list = this.listMacStocksKeys();
        break;
      case 'marketplace-skus':
        list = this.listMarketplaceSkuKeys();
        break;
      case 'order-lines':
        list = this.listOrderLineKeys();
        break;
      case 'order-returns':
        list = this.listOrderReturnKeys();
        break;
      case 'parts':
        list = this.listPartKeys();
        break;
      case 'production-orders':
        list = this.listProductionOrderKeys();
        break;
      case 'purchases':
        list = this.listPurchaseKeys();
        break;
      case 'spares':
        list = this.listSpareKeys();
        break;
      case 'special-process-priority':
        list = this.listSpecialProcessPriorityKeys();
        break;
      case 'suppliers':
        list = this.listSupplierKeys();
        break;
    }
    return list;
  }

  private listCommissionColumns(settings: GlobalSettings): Attribute<Commission>[] {
    if (!settings) return [];
    return [
      {
        id: 'marketplace_name',
        label: dicoLabelsService.getCollectionLabel('marketplace', 'short'),
        accessor: (commission: Commission) => commission.marketplace.display_name
      },
      {
        id: 'country',
        label: dicoLabelsService.getCollectionLabel('country', 'short'),
        accessor: (commission: Commission) => commission.country || '-',
        component: 'country-flag-keyboard'
      },
      {
        id: 'rate',
        label: dicoLabelsService.getCollectionLabel('rate'),
        sortKeys: ['rate'],
        accessor: (commission: Commission) => {
          return commission.rate != null ? commission.rate + '%' : '-';
        }
      },
      {
        id: 'fixed_amount',
        label: dicoLabelsService.getCollectionLabel('fixed_amount'),
        sortKeys: ['fixed_amount'],
        accessor: (commission: Commission) =>
          dicoLabelsService.formatAmount(commission.fixed_amount, Object.fromEntries(settings.currencies), 'EUR'),
        cssClass: 'text-center'
      },
      {
        id: 'start_at',
        label: dicoLabelsService.getCollectionLabel('start_date'),
        accessor: (commission: Commission) => datesService.format(commission.start_at, 'DD/MM/YYYY'),
        cssClass: 'text-center'
      },
      {
        id: 'end_at',
        label: dicoLabelsService.getCollectionLabel('end_date'),
        accessor: (commission: Commission) => {
          return commission.end_at != null ? datesService.format(commission.end_at, 'DD/MM/YYYY') : '-';
        },
        cssClass: 'text-center'
      }
    ];
  }

  private listDeviceColumns(settings: GlobalSettings): Attribute<Device>[] {
    const drives = Object.fromEntries(settings?.drives || []);
    const procs = Object.fromEntries(settings?.procs || []);
    const years = Object.fromEntries(settings?.years || []);

    return [
      {
        id: 'model_namestring',
        label: dicoLabelsService.getCollectionLabel('model_namestring', 'short'),
        sortKeys: ['model_namestring'],
        accessor: (device: Device) => device.model_namestring || '-'
      },
      {
        id: 'size',
        label: dicoLabelsService.getCollectionLabel('size', 'short'),
        sortKeys: ['size'],
        accessor: (device: Device) => {
          return device.size ? `${device.size}${dicoLabelsService.getUnit('screen_size')}` : '-';
        }
      },
      {
        id: 'year',
        label: dicoLabelsService.getCollectionLabel('year', 'short'),
        sortKeys: ['year'],
        accessor: (device: Device) => {
          return device.year && years[device.year] ? years[device.year].display_name : '-';
        }
      },
      {
        id: 'processor',
        label: 'Processeur',
        sortKeys: ['freq', 'proc'],
        accessor: (device: Device) => {
          return device.freq && device.proc
            ? `${device.freq} ${dicoLabelsService.getUnit('frequency')} ${procs[device.proc]}`
            : '-';
        }
      },
      {
        id: 'ram',
        label: dicoLabelsService.getCollectionLabel('ram', 'short'),
        sortKeys: ['ram'],
        accessor: (device: Device) => {
          return device.ram ? `${device.ram} ${dicoLabelsService.getUnit('capacity')}` : '-';
        }
      },
      {
        id: 'hard-drive',
        label: 'Disque dur',
        sortKeys: ['hdsize', 'hdtype'],
        accessor: (device: Device) => {
          return device.hdsize && device.hdtype
            ? `${device.hdsize} ${dicoLabelsService.getUnit('capacity')} ${drives[device.hdtype]}`
            : '-';
        }
      },
      {
        id: 'grade',
        label: dicoLabelsService.getCollectionLabel('grade', 'short'),
        sortKeys: ['grade'],
        accessor: (device: Device) => device.grade || null,
        component: 'device-grade'
      },
      {
        id: 'color',
        label: dicoLabelsService.getCollectionLabel('color', 'short'),
        sortKeys: ['color'],
        accessor: (device: Device) => device.color,
        component: 'device-color'
      },
      {
        id: 'keyboard',
        label: dicoLabelsService.getCollectionLabel('keyboard', 'short'),
        sortKeys: ['keyboard'],
        accessor: (device: Device) => {
          return device.keyboard + (device.keyboard_us ? '-US' : '');
        },
        component: 'country-flag-keyboard'
      },
      {
        id: 'serial_no',
        label: dicoLabelsService.getCollectionLabel('serial_no', 'short'),
        sortKeys: ['serial_no'],
        accessor: (device: Device) => device.serial_no || '-'
      },
      {
        id: 'stock_id',
        label: dicoLabelsService.getCollectionLabel('stock', 'short'),
        sortKeys: ['stock_id'],
        accessor: (device: Device) =>
          settings.stocks_status
            .filter(stocks_status => stocks_status[0] == device.stock_status_id)
            .map(stocks_status => {
              if (stocks_status[1].stock) {
                return stocks_status[1].stock.display_name;
              } else {
                return '-';
              }
            })[0],
        component: 'badge'
      },
      {
        id: 'status_id',
        label: dicoLabelsService.getCollectionLabel('status', 'short'),
        sortKeys: ['status_id'],
        accessor: (device: Device) =>
          settings.stocks_status
            .filter(stocks_status => stocks_status[0] == device.stock_status_id)
            .map(stocks_status => {
              if (stocks_status[1].status) {
                return stocks_status[1].status.display_name;
              } else {
                return '-';
              }
            })[0],
        component: 'badge'
      },
      {
        id: 'tax_scheme',
        label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short'),
        sortKeys: ['tax_scheme'],
        accessor: (device: Device) => device.tax_scheme || '-'
      },
      {
        id: 'location_name',
        label: dicoLabelsService.getCollectionLabel('location_name', 'short'),
        sortKeys: ['location_name'],
        accessor: (device: Device) => {
          return device.location ? device.location.name : null;
        },
        component: 'badge'
      },
      {
        id: 'marketplace_order_id',
        label: 'Commande associée',
        sortKeys: ['marketplace_order_id'],
        accessor: (device: Device) => device.marketplace_order_id
      },
      {
        id: 'last_comment',
        label: 'Dernier commentaire',
        sortKeys: ['last_comment'],
        accessor: (device: Device) => device.last_comment,
        component: 'comment-cell'
      }
    ];
  }

  private listOrderLineColumns(settings: GlobalSettings): Attribute<Order | OrderLine>[] {
    if (!settings) return [];
    return [
      {
        id: 'ordered_item_namestring',
        label: dicoLabelsService.getCollectionLabel('ordered_item_namestring', 'short'),
        sortKeys: ['ordered_item_namestring'],
        accessor: (order_line: OrderLine) => {
          const model = order_line.ordered_item_namestring;
          const size = get(order_line, 'ordered_item.size');
          return (model || '-') + (model && size ? ' ' + size + dicoLabelsService.getUnit('screen_size') : '');
        }
      },
      {
        id: 'ordered_item_desc',
        label: dicoLabelsService.getCollectionLabel('ordered_item_desc', 'short'),
        sortKeys: ['ordered_item_desc'],
        accessor: (order_line: OrderLine) => dicoLabelsService.formatItemDesc(order_line.ordered_item, settings),
        cssClass: 'no-wrap'
      },
      {
        id: 'tax_scheme',
        label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short'),
        cssClass: 'text-center',
        accessor: (order_line: OrderLine) => order_line.tax_scheme || '-'
      },
      {
        id: 'price',
        label: dicoLabelsService.getCollectionLabel('price', 'short'),
        sortKeys: ['price'],
        accessor: (order_line: OrderLine) =>
          dicoLabelsService.formatAmount(
            order_line.price,
            Object.fromEntries(settings.currencies),
            order_line.order?.currency
          )
      },
      {
        id: 'customer',
        label: dicoLabelsService.getCollectionLabel('customer', 'short'),
        sortKeys: ['first_name', 'last_name'],
        accessor: (order_line: OrderLine) => {
          return dicoLabelsService.formatName(order_line.shipping_info);
        }
      },
      {
        id: 'country',
        label: dicoLabelsService.getCollectionLabel('country', 'short'),
        sortKeys: ['country'],
        accessor: (order_line: OrderLine) => order_line.shipping_info && order_line.shipping_info.country,
        component: 'country-flag'
      },
      {
        id: 'marketplace',
        label: dicoLabelsService.getCollectionLabel('marketplace', 'short'),
        sortKeys: ['marketplace'],
        accessor: (order_line: OrderLine) => order_line.order.marketplace.display_name
      },
      {
        id: 'order_date',
        label: 'Date',
        sortKeys: ['order_date'],
        accessor: (order_line: OrderLine) => {
          const date = order_line.return_id != null ? order_line.created_at : order_line.order.order_date;
          return date ? datesService.format(date) : '-';
        }
      },
      {
        id: 'max_shipping_date',
        label: dicoLabelsService.getCollectionLabel('max_shipping_date', 'short'),
        sortKeys: ['max_shipping_date'],
        cssClass: 'text-center',
        accessor: (order_line: OrderLine) => {
          return order_line.max_shipping_date ? datesService.format(order_line.max_shipping_date) : '-';
        }
      },
      {
        id: 'nb_items',
        label: dicoLabelsService.getCollectionLabel('nb_items', 'short'),
        sortKeys: ['nb_items'],
        cssClass: 'text-center',
        accessor: (order_line: OrderLine) => (order_line.nb_items <= 1 ? '-' : order_line.nb_items)
      },
      {
        id: 'new_match_required',
        label: dicoLabelsService.getCollectionLabel('new_match_required', 'short'),
        sortKeys: ['new_match_required'],
        cssClass: 'text-center',
        accessor: (order_line: OrderLine) => (order_line.new_match_required ? 'Oui' : '-')
      },
      {
        id: 'out_of_stock',
        label: dicoLabelsService.getCollectionLabel('out_of_stock', 'short'),
        sortKeys: ['out_of_stock'],
        cssClass: 'text-center',
        accessor: (order_line: OrderLine) => (order_line.out_of_stock ? 'Oui' : '-')
      },
      {
        id: 'is_exchanged',
        label: dicoLabelsService.getCollectionLabel('is_exchanged', 'short'),
        sortKeys: ['is_exchanged'],
        cssClass: 'text-center',
        accessor: (order_line: OrderLine) => (order_line.is_exchanged || order_line.return_id !== null ? 'Oui' : '-')
      },
      {
        id: 'device_serial_no',
        label: 'Ordinateur associé',
        sortKeys: ['device_serial_no'],
        cssClass: 'text-center',
        accessor: (order_line: OrderLine) => order_line.device_serial_no || ''
      },
      {
        id: 'location_name',
        label: dicoLabelsService.getCollectionLabel('location_name', 'short'),
        sortKeys: ['device_location'],
        cssClass: 'text-center',
        component: 'badge',
        accessor: (order_line: OrderLine) => order_line.device_location || ''
      },

      {
        id: 'last_comment',
        label: 'Dernier commentaire',
        sortKeys: ['last_comment'],
        accessor: (order_line: OrderLine) => order_line.last_comment?.content || ''
      }
    ];
  }

  private listOrderColumns(settings: GlobalSettings): Attribute<Order>[] {
    if (!settings) return [];
    return [
      {
        id: 'marketplace_order_id',
        label: dicoLabelsService.getCollectionLabel('marketplace_order_id', 'short'),
        sortKeys: ['marketplace_order_id'],
        accessor: (order: Order) => order.marketplace_order_id || '-'
      },
      {
        id: 'price',
        label: dicoLabelsService.getCollectionLabel('price', 'short'),
        sortKeys: ['price'],
        accessor: (order: Order) =>
          dicoLabelsService.formatAmount(order.total_price, Object.fromEntries(settings.currencies), order.currency)
      },
      {
        id: 'customer',
        label: dicoLabelsService.getCollectionLabel('customer', 'short'),
        sortKeys: ['first_name', 'last_name'],
        accessor: (order: Order) => {
          return dicoLabelsService.formatName(order.shipping_info);
        }
      },
      {
        id: 'country',
        label: dicoLabelsService.getCollectionLabel('country', 'short'),
        sortKeys: ['country'],
        accessor: (order: Order) => order.shipping_info && order.shipping_info.country,
        component: 'country-flag'
      },
      {
        id: 'marketplace',
        label: dicoLabelsService.getCollectionLabel('marketplace', 'short'),
        sortKeys: ['marketplace'],
        accessor: (order: Order) => order.marketplace.display_name || '-'
      },
      {
        id: 'order_date',
        label: 'Date',
        sortKeys: ['order_date'],
        accessor: (order: Order) => {
          return order.order_date ? datesService.format(order.order_date) : '-';
        }
      },
      {
        id: 'nb_items',
        label: dicoLabelsService.getCollectionLabel('nb_items', 'short'),
        cssClass: 'text-center',
        accessor: (order: Order) => (order.nb_items <= 1 ? '-' : order.nb_items)
      },
      {
        id: 'last_comment',
        label: 'Dernier commentaire',
        sortKeys: ['last_comment'],
        accessor: (order: Order) => order.last_comment?.content || ''
      }
    ];
  }

  listFileTable(): Attribute<PurchaseFiles>[] {
    return [
      {
        id: 'initial_supplier_file',
        label: dicoLabelsService.getCollectionLabel('initial_supplier_file', 'short'),
        sortKeys: ['initial_supplier_file'],
        accessor: (purchase: Purchase) => purchase.initial_supplier_file
      },
      {
        id: 'analysis_supplier_file',
        label: dicoLabelsService.getCollectionLabel('analysis_supplier_file', 'short'),
        sortKeys: ['analysis_supplier_file'],
        accessor: (purchase: Purchase) => purchase.analysis_supplier_file
      },
      {
        id: 'payment_confirmation_file',
        label: dicoLabelsService.getCollectionLabel('payment_confirmation_file', 'short'),
        sortKeys: ['payment_confirmation_file'],
        accessor: (purchase: Purchase) => purchase.payment_confirmation_file
      },
      {
        id: 'packaging_photo_file',
        label: dicoLabelsService.getCollectionLabel('packaging_photo_file', 'short'),
        sortKeys: ['packaging_photo_file'],
        accessor: (purchase: Purchase) => purchase.packaging_photo_file
      },
      {
        id: 'rma_file',
        label: dicoLabelsService.getCollectionLabel('rma_file', 'short'),
        sortKeys: ['rma_file'],
        accessor: (purchase: Purchase) => (purchase.rma_file ? purchase.rma_file : '-')
      }
    ];
  }

  listAttachmentsFilesColumns(): Attribute<FileStorageAttachments>[] {
    return [
      {
        id: 'filename',
        label: dicoLabelsService.getCollectionLabel('filess'),
        accessor: (fileStorageAttachment: FileStorageAttachments) => fileStorageAttachment.blob_id.filename
      },
      {
        id: 'name',
        label: dicoLabelsService.getCollectionLabel('files_type'),
        accessor: (fileStorageAttachment: FileStorageAttachments) =>
          dicoLabelsService.getCollectionLabel(fileStorageAttachment.name)
      },
      {
        id: 'created_at',
        label: dicoLabelsService.getCollectionLabel('created_at', 'long'),
        cssClass: 'text-center',
        accessor: (fileStorageAttachment: FileStorageAttachments) =>
          datesService.format(fileStorageAttachment.created_at)
      }
    ];
  }

  private listOrderReturnColumns(
    settings: GlobalSettings,
    filters: FilterOptions<'order-returns'>
  ): Attribute<OrderReturn>[] {
    if (!settings) return [];

    const columns = [
      {
        id: 'return_type',
        label: dicoLabelsService.getCollectionLabel('return_type', 'short'),
        accessor: (orderReturn: OrderReturn) => {
          return orderReturn.return_type ? Object.fromEntries(settings.return_types)[orderReturn.return_type] : '-';
        }
      },
      {
        id: 'is_urgent',
        label: dicoLabelsService.getCollectionLabel('is_urgent', 'short'),
        accessor: (orderReturn: OrderReturn) => !!orderReturn.is_urgent,
        component: 'urgent-flag'
      },
      {
        id: 'ordered_item_namestring',
        label: dicoLabelsService.getCollectionLabel('ordered_item_namestring', 'short'),
        accessor: (orderReturn: OrderReturn) => {
          const model = orderReturn.order_line.ordered_item_namestring;
          return model || '-';
        }
      },
      {
        id: 'device_serial_no',
        label: 'Numéro de série',
        accessor: (orderReturn: OrderReturn) => orderReturn.device?.serial_no
      },
      {
        id: 'reasons',
        label: dicoLabelsService.getCollectionLabel('reasons'),
        accessor: (orderReturn: OrderReturn) => {
          return orderReturn.reasons.length > 0
            ? orderReturn.reasons
                .map(el => settings.customer_declared_faulties.find(s => s.value == el)['display_name'])
                .join(', ')
            : '-';
        },
        cssClass: 'overflow'
      },
      {
        id: 'customer',
        label: dicoLabelsService.getCollectionLabel('customer', 'short'),
        accessor: (orderReturn: OrderReturn) => {
          return dicoLabelsService.formatName(orderReturn.order_line.order.shipping_info);
        }
      },
      {
        id: 'return_order_line_id',
        label: dicoLabelsService.getCollectionLabel('display_order_line', 'short'),
        accessor: () => 'Afficher la ligne'
      },
      {
        id: 'return_order_id',
        label: dicoLabelsService.getCollectionLabel('marketplace_order_id', 'short'),
        accessor: (orderReturn: OrderReturn) => {
          return orderReturn.order_line.order.marketplace_order_id;
        }
      },
      {
        id: 'marketplace',
        label: dicoLabelsService.getCollectionLabel('marketplace', 'short'),
        accessor: (orderReturn: OrderReturn) => {
          return orderReturn.order_line.order.marketplace.display_name;
        }
      },
      {
        id: 'max_shipping_date',
        label: dicoLabelsService.getCollectionLabel('max_shipping_date', 'short'),
        accessor: (orderReturn: OrderReturn) => {
          return orderReturn.max_shipping_date ? datesService.format(orderReturn.max_shipping_date) : '-';
        },
        cssClass: 'text-center'
      },
      {
        id: 'order_returns_nb',
        label: dicoLabelsService.getCollectionLabel('order_returns_nb'),
        accessor: (orderReturn: OrderReturn) => {
          return orderReturn.device?.order_returns_nb || '-';
        },
        cssClass: 'text-center'
      },
      {
        id: 'stock_id',
        label: dicoLabelsService.getCollectionLabel('stock_status_id', 'long'),
        accessor: (orderReturn: OrderReturn) => orderReturn.device?.stock_status_id,
        component: 'device-stock',
        cssClass: 'text-center'
      },
      {
        id: 'location_name',
        label: dicoLabelsService.getCollectionLabel('location_name', 'short'),
        accessor: (orderReturn: OrderReturn) => {
          return orderReturn.device?.location?.name || null;
        },
        cssClass: 'text-center',
        component: 'badge'
      },
      {
        id: 'process_handled_by',
        label: dicoLabelsService.getCollectionLabel('process_handled_by', 'short'),
        accessor: (orderReturn: OrderReturn) => {
          return orderReturn.process_handled_by ? dicoLabelsService.formatName(orderReturn.process_handled_by) : '-';
        },
        cssClass: 'text-center'
      }
    ];

    if (filters['status'] === 'sav_validated') {
      columns.push({
        id: 'shipping_date',
        label: dicoLabelsService.getCollectionLabel('shipping_date', 'short'),
        accessor: (orderReturn: OrderReturn) => {
          return orderReturn.process_validated_at ? datesService.format(orderReturn.process_validated_at) : '-';
        },
        cssClass: 'text-center'
      });
    }

    return columns;
  }

  private listProductionOrderColumns(settings: GlobalSettings): Attribute<ProductionOrder>[] {
    if (!settings) return [];

    return [
      {
        id: 'model_namestring',
        label: dicoLabelsService.getCollectionLabel('model_namestring', 'short'),
        sortKeys: ['model_namestring'],
        accessor: (productionOrder: ProductionOrder) => {
          const model = productionOrder.model_namestring;
          const size = productionOrder.size;
          return model + ' ' + size + dicoLabelsService.getUnit('screen_size');
        }
      },
      {
        id: 'ordered_item_desc',
        label: dicoLabelsService.getCollectionLabel('ordered_item_desc', 'short'),
        sortKeys: ['ordered_item_desc'],
        accessor: (productionOrder: ProductionOrder) =>
          dicoLabelsService.formatItemDesc(productionOrder.ordered_device, settings),
        cssClass: 'no-wrap'
      },
      {
        id: 'keyboard',
        label: dicoLabelsService.getCollectionLabel('keyboard', 'short'),
        sortKeys: ['keyboard'],
        accessor: (productionOrder: ProductionOrder) => productionOrder.keyboard,
        component: 'country-flag-keyboard'
      },
      {
        id: 'created_at',
        label: 'Date',
        sortKeys: ['created_at'],
        accessor: (productionOrder: ProductionOrder) => {
          return productionOrder.created_at ? datesService.format(productionOrder.created_at) : '-';
        }
      },
      {
        id: 'device_serial_no',
        label: 'Ordinateur associé',
        sortKeys: ['device_serial_no'],
        cssClass: 'text-center',
        accessor: (productionOrder: ProductionOrder) => productionOrder.device_serial_no
      },
      {
        id: 'last_comment',
        label: 'Dernier commentaire',
        sortKeys: ['last_comment'],
        accessor: (productionOrder: ProductionOrder) => productionOrder.last_comment?.content || '-'
      }
    ];
  }

  private listMatchErrorColumns(settings: GlobalSettings): Attribute<MatchError>[] {
    const years = Object.fromEntries(settings?.years || []);

    return [
      {
        id: 'model_namestring',
        label: dicoLabelsService.getCollectionLabel('model_namestring', 'short'),
        accessor: (error: MatchError) => error?.match?.device?.model_namestring || '-'
      },
      {
        id: 'size',
        label: dicoLabelsService.getCollectionLabel('size', 'short'),
        accessor: (error: MatchError) => {
          const size = error?.match?.device?.size;
          return size ? `${size}${dicoLabelsService.getUnit('screen_size')}` : '-';
        }
      },
      {
        id: 'year',
        label: dicoLabelsService.getCollectionLabel('year', 'short'),
        accessor: (error: MatchError) => {
          const year = error?.match?.device?.year;
          return year && years[year] ? years[year].display_name : '-';
        }
      },
      {
        id: 'processor',
        label: 'Processeur',
        accessor: (error: MatchError) => {
          const freq = error?.match?.device?.freq;
          const proc = error?.match?.device?.proc;
          return freq && proc ? `${freq} ${dicoLabelsService.getUnit('frequency')} ${proc}` : '-';
        }
      },
      {
        id: 'ram',
        label: dicoLabelsService.getCollectionLabel('ram', 'short'),
        accessor: (error: MatchError) => {
          const ram = error?.match?.device?.ram;
          return ram ? `${ram} ${dicoLabelsService.getUnit('capacity')}` : '-';
        }
      },
      {
        id: 'color',
        label: dicoLabelsService.getCollectionLabel('color', 'short'),
        accessor: (error: MatchError) => error?.match?.device?.color || '-'
      },
      {
        id: 'error',
        label: dicoLabelsService.getCollectionLabel('error', 'short'),
        accessor: (error: MatchError) => error
      }
    ];
  }

  private listDispatchCagesColumns(): Attribute<Cage>[] {
    return [
      {
        id: 'name',
        label: dicoLabelsService.getCollectionLabel('cage'),
        accessor: (cage: Cage) => cage.name
      },
      {
        id: 'created_at',
        label: dicoLabelsService.getCollectionLabel('created_at'),
        sortKeys: ['created_at'],
        accessor: (cage: Cage) => datesService.format(cage.created_at)
      },
      {
        id: 'state',
        label: dicoLabelsService.getCollectionLabel('state'),
        sortKeys: ['state'],
        cssClass: 'text-center',
        accessor: (cage: Cage) => cage.state
      },
      {
        id: 'picked_up_at',
        label: dicoLabelsService.getCollectionLabel('picked_up_at'),
        sortKeys: ['picked_up_at'],
        cssClass: 'text-center',
        accessor: (cage: Cage) => (cage?.picked_up_at ? datesService.format(cage.picked_up_at) : '-')
      }
    ];
  }
  private listsPartColumns(): Attribute<Part>[] {
    return [
      {
        id: 'ppart_type',
        label: dicoLabelsService.getCollectionLabel('part_type'),
        accessor: (part: Part) => part.part_type,
        cssClass: 'text-center'
      },
      {
        id: 'serial_number',
        label: dicoLabelsService.getCollectionLabel('serial_number'),
        cssClass: 'text-center',
        sortKeys: ['serial_number'],
        accessor: (part: Part) => part.serial_number
      },
      {
        id: 'modeles',
        label: dicoLabelsService.getCollectionLabel('modeles'),
        sortKeys: ['modele'],
        cssClass: 'text-center',
        accessor: (part: Part) => part.modele
      },
      {
        id: 'taille',
        label: dicoLabelsService.getCollectionLabel('taille'),
        sortKeys: ['taille'],
        cssClass: 'text-center',
        accessor: (part: Part) => part.taille
      },
      {
        id: 'processeur',
        label: dicoLabelsService.getCollectionLabel('processeur'),
        sortKeys: ['processeur'],
        cssClass: 'text-center',
        accessor: (part: Part) => part.processeur
      },
      {
        id: 'color',
        label: dicoLabelsService.getCollectionLabel('color', 'short'),
        sortKeys: ['color'],
        cssClass: 'text-center',
        accessor: (part: Part) => part.color || '-',
        component: 'device-color'
      },
      {
        id: 'annee_modele',
        label: dicoLabelsService.getCollectionLabel('annee_modele'),
        sortKeys: ['annee_modele'],
        cssClass: 'text-center',
        accessor: (part: Part) => {
          if (part.annee_debut && part.annee_fin) {
            return `${part.annee_debut} - ${part.annee_fin}`;
          } else if (part.annee_debut) {
            return part.annee_debut;
          } else if (part.annee_fin) {
            return part.annee_fin;
          } else {
            return '-';
          }
        }
      }
    ];
  }
  private listPurchasesColumns(settings: GlobalSettings, statut: PurcheStatus): Attribute<Purchase>[] {
    if (!settings) return [];

    // Base columns
    const columns: Attribute<Purchase>[] = [
      {
        id: 'reference_internal',
        label: dicoLabelsService.getCollectionLabel('reference_internal'),
        accessor: (purchase: Purchase) => purchase.reference_internal || '-'
      },
      {
        id: 'supplier',
        label: dicoLabelsService.getCollectionLabel('supplier', 'long'),
        accessor: (purchase: Purchase) => purchase.supplier.display_name
      },
      {
        id: 'reference_supplier',
        label: dicoLabelsService.getCollectionLabel('reference_supplier'),
        accessor: (purchase: Purchase) => purchase.reference_supplier
      },
      {
        id: 'amount',
        label: dicoLabelsService.getCollectionLabel('amount'),
        accessor: (purchase: Purchase) => purchase.amount + '€' || '-'
      },
      {
        id: 'shipping_carrier',
        label: dicoLabelsService.getCollectionLabel('shipping_carrier'),
        accessor: (purchase: Purchase) => purchase.shipping_carrier || '-'
      },
      {
        id: 'quantity',
        label: dicoLabelsService.getCollectionLabel('quantity', 'short'),
        accessor: (purchase: Purchase) => {
          let quantity_label = '-';
          if (purchase.quantity != null && purchase.quantity > 0) {
            quantity_label = purchase.quantity_received + ' / ' + purchase.quantity;
            if (purchase.quantity_received == null) {
              quantity_label = '0 / ' + purchase.quantity;
            }
          }
          return quantity_label;
        }
      }
    ];

    if (
      statut === 'control' ||
      statut === 'validated' ||
      statut === 'shipping' ||
      statut === 'rma' ||
      statut === 'received'
    ) {
      columns.splice(1, 0, {
        id: 'opportunity_name',
        label: dicoLabelsService.getCollectionLabel('opportunity_name'),
        accessor: (purchase: Purchase) => purchase.opportunity_name || '-'
      });
      columns.push(
        {
          id: 'nb_expected_packaging',
          label: dicoLabelsService.getCollectionLabel('remaining_quantity'),
          accessor: (purchase: Purchase) => {
            let quantity_label = '-';
            if (purchase.nb_expected_packaging != null && purchase.nb_expected_packaging > 0) {
              quantity_label = purchase.nb_received_packaging + ' / ' + purchase.nb_expected_packaging;
              if (purchase.nb_received_packaging == null) {
                quantity_label = '0 / ' + purchase.nb_expected_packaging;
              }
            }
            return quantity_label;
          }
        },
        {
          id: 'delivery_expected_date',
          label: dicoLabelsService.getCollectionLabel('delivery_expected_date'),
          accessor: (purchase: Purchase) => datesService.format(purchase.delivery_expected_date, 'Do MMMM YYYY') || '-'
        },
        {
          id: 'warranty',
          label: dicoLabelsService.getCollectionLabel('warranty_date'),
          accessor: (purchase: Purchase) => {
            const warranty = purchase.warranty;
            return warranty + dicoLabelsService.getUnit('month');
          }
        }
      );
    }

    if (
      !(
        statut === 'control' ||
        statut === 'validated' ||
        statut === 'shipping' ||
        statut === 'rma' ||
        statut === 'received'
      )
    ) {
      columns.push({
        id: 'created_at',
        label: dicoLabelsService.getCollectionLabel('created'),
        accessor: (purchase: Purchase) => datesService.format(purchase.created_at) || '-'
      });
    }

    return columns;
  }

  private listSupplierColumns(settings: GlobalSettings): Attribute<Supplier>[] {
    if (!settings) return [];

    // Base columns
    const columns: Attribute<Supplier>[] = [
      {
        id: 'display_name',
        label: dicoLabelsService.getCollectionLabel('display_name'),
        accessor: (supplier: Supplier) => supplier.display_name || '-'
      },
      {
        id: 'country',
        label: dicoLabelsService.getCollectionLabel('country'),
        accessor: (supplier: Supplier) => supplier.country || '-'
      },
      {
        id: 'currency',
        label: dicoLabelsService.getCollectionLabel('currency'),
        accessor: (supplier: Supplier) => supplier.currency || '-'
      },
      {
        id: 'payment_timing',
        label: dicoLabelsService.getCollectionLabel('payment_timing'),
        accessor: (supplier: Supplier) =>
          supplier.payment_timing ? dicoLabelsService.getCollectionLabel(supplier.payment_timing) : '-'
      },
      {
        id: 'warranty',
        label: dicoLabelsService.getCollectionLabel('warranty'),
        accessor: (supplier: Supplier) => supplier.warranty + ' ' + dicoLabelsService.getUnit('day')
      },
      {
        id: 'tax_scheme',
        label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short'),
        accessor: (supplier: Supplier) => supplier.tax_scheme || '-'
      }
    ];
    return columns;
  }

  private listSpareColumns(spareType: SpareType): Attribute<Spare>[] {
    const columns: Attribute<Spare>[] = [
      {
        id: 'code',
        label: dicoLabelsService.getCollectionLabel('code', 'short'),
        accessor: (spare: Spare) => spare?.code || '-'
      },
      {
        id: 'desc',
        label: dicoLabelsService.getCollectionLabel('desc', 'short'),
        accessor: (spare: Spare) => spare?.desc || '-'
      },
      {
        id: 'quantity',
        label: dicoLabelsService.getCollectionLabel('quantity', 'long'),
        accessor: (spare: Spare) => ({
          quantity: spare?.quantity,
          buffer: spare?.quantity_buffer,
          stockoutDate: spare?.stockout_date
        }),
        component: 'quantity-control',
        cssClass: 'text-center'
      },
      {
        id: 'quantity_desired',
        label: dicoLabelsService.getCollectionLabel('quantity_desired', 'long'),
        accessor: (spare: Spare) =>
          isNil(spare?.quantity_desired) || isNaN(spare?.quantity_desired) ? '-' : spare?.quantity_desired,
        cssClass: 'text-center'
      },
      {
        id: 'price_avg',
        label: dicoLabelsService.getCollectionLabel('price_avg', 'long'),
        accessor: (spare: Spare) => (isNil(spare?.price_avg) || isNaN(spare?.price_avg) ? '-' : spare?.price_avg),
        cssClass: 'text-center'
      }
    ];

    // if (spareType === 'hdd' || spareType === 'ram') {
    //   columns.push({
    //     id: 'quantity_in_match_stock',
    //     label: dicoLabelsService.getCollectionLabel('quantity_in_match_stock', 'short'),
    //     accessor: (spare: Spare) =>
    //       isNil(spare?.quantity_in_match_stock) || isNaN(spare?.quantity_in_match_stock)
    //         ? '-'
    //         : spare?.quantity_in_match_stock,
    //     cssClass: 'text-center'
    //   });
    // }
    //
    // if (spareType === 'charger') {
    //   columns.push({
    //     id: 'devices_in_match_stock',
    //     label: dicoLabelsService.getCollectionLabel('devices_in_match_stock', 'short'),
    //     accessor: (spare: Spare) =>
    //       isNil(spare?.devices_in_match_stock) || isNaN(spare?.devices_in_match_stock)
    //         ? '-'
    //         : spare?.devices_in_match_stock,
    //     cssClass: 'text-center'
    //   });
    // }

    if (spareType === 'battery') {
      columns.push({
        id: 'quantity_def_in_match_stock',
        label: dicoLabelsService.getCollectionLabel('quantity_def_in_match_stock', 'short'),
        accessor: (spare: Spare) =>
          isNil(spare?.quantity_def_in_match_stock) || isNaN(spare?.quantity_def_in_match_stock)
            ? '-'
            : spare?.quantity_def_in_match_stock,
        cssClass: 'text-center'
      });
    }

    columns.push({
      id: 'models',
      label: dicoLabelsService.getCollectionLabel('models', 'short'),
      accessor: (spare: Spare) => (spare?.models?.length > 0 ? spare.models.join(', ') : '-')
    });

    return columns;
  }

  private listSpecialProcessPriority(settings: GlobalSettings): Attribute<Device>[] {
    const years = Object.fromEntries(settings?.years || []);
    return [
      {
        id: 'model_namestring',
        label: dicoLabelsService.getCollectionLabel('model_namestring', 'short'),
        sortKeys: ['model_namestring'],
        accessor: (device: Device) => device.model_namestring || '-'
      },
      {
        id: 'serial_no',
        label: dicoLabelsService.getCollectionLabel('serial_no', 'short'),
        sortKeys: ['serial_no'],
        accessor: (device: Device) => device.serial_no || '-'
      },
      {
        id: 'grade',
        label: dicoLabelsService.getCollectionLabel('grade', 'short'),
        sortKeys: ['grade'],
        accessor: (device: Device) => device.grade || null,
        component: 'device-grade'
      },
      {
        id: 'aspiring_grade',
        label: dicoLabelsService.getCollectionLabel('aspiring_grade', 'short'),
        accessor: (device: Device) => device.aspiring_grade || null,
        component: 'device-grade'
      },
      {
        id: 'year',
        label: dicoLabelsService.getCollectionLabel('year', 'short'),
        sortKeys: ['year'],
        accessor: (device: Device) => {
          return device.year && years[device.year] ? years[device.year].display_name : '-';
        }
      },
      {
        id: 'size',
        label: dicoLabelsService.getCollectionLabel('size', 'short'),
        sortKeys: ['size'],
        accessor: (device: Device) => {
          return device.size ? `${device.size}${dicoLabelsService.getUnit('screen_size')}` : '-';
        }
      },
      {
        id: 'color',
        label: dicoLabelsService.getCollectionLabel('color', 'short'),
        sortKeys: ['color'],
        accessor: (device: Device) => device.color,
        component: 'device-color'
      },
      {
        id: 'location_name',
        label: dicoLabelsService.getCollectionLabel('location_name', 'short'),
        accessor: (device: Device) => {
          return device.location ? device.location.name : '-';
        },
        component: 'badge'
      },
      {
        id: 'iteration',
        label: dicoLabelsService.getCollectionLabel('iteration', 'short'),
        accessor: (device: Device) => {
          return device.spp_iteration ? device.spp_iteration : '-';
        },
        component: 'badge'
      }
    ];
  }

  private listConsumableColumns(): Attribute<Consumable>[] {
    const columns = [
      {
        id: 'code',
        label: dicoLabelsService.getCollectionLabel('code', 'short'),
        accessor: (consumable: Consumable) => consumable?.code || '-'
      },
      {
        id: 'desc',
        label: dicoLabelsService.getCollectionLabel('desc', 'short'),
        accessor: (consumable: Consumable) => consumable?.desc || '-'
      },
      {
        id: 'quantity_supplier',
        label: dicoLabelsService.getCollectionLabel('quantity_supplier', 'short'),
        accessor: (consumable: Consumable) =>
          isNil(consumable?.quantity_supplier) || isNaN(consumable?.quantity_supplier)
            ? '-'
            : consumable?.quantity_supplier,
        cssClass: 'text-center'
      },
      {
        id: 'quantity',
        label: 'Quantité chez Okamac', // Specificity
        accessor: (consumable: Consumable) =>
          isNil(consumable?.quantity) || isNaN(consumable?.quantity) ? '-' : consumable?.quantity,
        cssClass: 'text-center'
      },
      {
        id: 'quantity_total',
        label: dicoLabelsService.getCollectionLabel('quantity_total', 'short'),
        accessor: (consumable: Consumable) => ({
          quantity:
            isNil(consumable?.quantity) ||
            isNaN(consumable?.quantity) ||
            isNil(consumable?.quantity_supplier) ||
            isNaN(consumable?.quantity_supplier)
              ? null
              : consumable?.quantity + consumable?.quantity_supplier,
          buffer: consumable?.quantity_buffer,
          stockoutDate: consumable?.stockout_date
        }),
        component: 'quantity-control',
        cssClass: 'text-center'
      },
      {
        id: 'quantity_desired',
        label: dicoLabelsService.getCollectionLabel('quantity_desired', 'short'),
        accessor: (consumable: Consumable) =>
          isNil(consumable?.quantity_desired) || isNaN(consumable?.quantity_desired)
            ? '-'
            : consumable?.quantity_desired,
        cssClass: 'text-center'
      },
      {
        id: 'price_avg',
        label: dicoLabelsService.getCollectionLabel('price_avg', 'short'),
        accessor: (consumable: Consumable) =>
          isNil(consumable?.price_avg) || isNaN(consumable?.price_avg) ? '-' : consumable?.price_avg,
        cssClass: 'text-center'
      },
      {
        id: 'models',
        label: dicoLabelsService.getCollectionLabel('models', 'short'),
        accessor: (consumable: Consumable) => (consumable?.models?.length > 0 ? consumable.models.join(', ') : '-')
      }
    ];

    return columns;
  }

  private listMacStocksColumns(settings: GlobalSettings): Attribute<MacStock>[] {
    const drives = Object.fromEntries(settings?.drives || []);
    const procs = Object.fromEntries(settings?.procs || []);
    const years = Object.fromEntries(settings?.years || []);
    return [
      {
        id: 'mac_stock_config.mac_ref.model_string',
        label: dicoLabelsService.getCollectionLabel('model_namestring', 'short'),
        accessor: (stock: MacStock) => stock?.mac_stock_config?.mac_ref?.model_string || '-'
      },
      {
        id: 'mac_stock_config.mac_ref.size',
        label: dicoLabelsService.getCollectionLabel('size', 'short'),
        accessor: (stock: MacStock) => {
          const size = stock?.mac_stock_config?.mac_ref?.size;
          return size ? `${size}${dicoLabelsService.getUnit('screen_size')}` : '-';
        }
      },
      {
        id: 'mac_stock_config.mac_ref.year',
        label: dicoLabelsService.getCollectionLabel('year', 'short'),
        accessor: (stock: MacStock) => {
          const year = stock?.mac_stock_config?.mac_ref?.year;
          return year && years[year] ? years[year].display_name : '-';
        }
      },
      {
        id: 'processor',
        label: 'Processeur',
        accessor: (stock: MacStock) => {
          const freq = stock?.mac_stock_config?.mac_ref?.freq;
          const proc = stock?.mac_stock_config?.mac_ref?.proc;
          return freq && proc ? `${freq} ${dicoLabelsService.getUnit('frequency')} ${procs[proc]}` : '-';
        }
      },
      {
        id: 'mac_stock_config.ram',
        label: dicoLabelsService.getCollectionLabel('ram', 'short'),
        accessor: (stock: MacStock) => {
          const ram = stock?.mac_stock_config?.ram;
          return ram ? `${ram} ${dicoLabelsService.getUnit('capacity')}` : '-';
        }
      },
      {
        id: 'hard-drive',
        label: 'Disque dur',
        accessor: (stock: MacStock) => {
          const hdsize = stock?.mac_stock_config?.hdsize;
          const hdtype = stock?.mac_stock_config?.hdtype;
          return hdsize && hdtype ? `${hdsize} ${dicoLabelsService.getUnit('capacity')} ${drives[hdtype]}` : '-';
        }
      },
      {
        id: 'mac_stock_config.mac_ref.color',
        label: dicoLabelsService.getCollectionLabel('color', 'short'),
        accessor: (stock: MacStock) => stock?.mac_stock_config?.mac_ref?.color || '-',
        component: 'device-color'
      },
      {
        id: 'devices_count_c',
        label: dicoLabelsService.getCollectionLabel('devices_count_c', 'short'),
        accessor: (stock: MacStock) => ({
          quantity: stock?.devices_count_c,
          buffer: stock?.mac_stock_config?.quantity_buffer_c,
          stockoutDate: stock?.mac_stock_config?.stockout_date
        }),
        component: 'quantity-control',
        cssClass: 'text-center'
      },
      {
        id: 'devices_count_b',
        label: dicoLabelsService.getCollectionLabel('devices_count_b', 'short'),
        accessor: (stock: MacStock) => ({
          quantity: stock?.devices_count_b,
          buffer: stock?.mac_stock_config?.quantity_buffer_b,
          stockoutDate: stock?.mac_stock_config?.stockout_date
        }),
        component: 'quantity-control',
        cssClass: 'text-center'
      },
      {
        id: 'devices_count_a',
        label: dicoLabelsService.getCollectionLabel('devices_count_a', 'short'),
        accessor: (stock: MacStock) => ({
          quantity: stock?.devices_count_a,
          buffer: stock?.mac_stock_config?.quantity_buffer_a,
          stockoutDate: stock?.mac_stock_config?.stockout_date
        }),
        component: 'quantity-control',
        cssClass: 'text-center'
      },
      {
        id: 'devices_count_aa',
        label: dicoLabelsService.getCollectionLabel('devices_count_aa', 'short'),
        accessor: (stock: MacStock) => ({
          quantity: stock?.devices_count_aa,
          buffer: stock?.mac_stock_config?.quantity_buffer_aa,
          stockoutDate: stock?.mac_stock_config?.stockout_date
        }),
        component: 'quantity-control',
        cssClass: 'text-center'
      },
      {
        id: 'devices_count',
        label: dicoLabelsService.getCollectionLabel('devices_count', 'short'),
        accessor: (stock: MacStock) =>
          stock?.devices_count_aa + stock?.devices_count_a + stock?.devices_count_b + stock?.devices_count_c,
        cssClass: 'text-center'
      },
      {
        id: 'mac_stock_config.is_favorite', // Necessary to edit through table
        label: dicoLabelsService.getCollectionLabel('is_favorite', 'short'),
        accessor: (stock: MacStock) => stock?.mac_stock_config?.is_favorite,
        component: 'table-checkbox'
      }
    ];
  }

  private listPurchaseQueriesColumns(category: PurchasableCategory): Attribute<PurchaseQuery>[] {
    const columns: Attribute<PurchaseQuery>[] =
      category === 'other'
        ? [
            {
              id: 'other_desc',
              label: dicoLabelsService.getCollectionLabel('other_desc', 'short'),
              accessor: (query: PurchaseQuery) => query?.other_desc || '-'
            },
            {
              id: 'other_price',
              label: dicoLabelsService.getCollectionLabel('other_price', 'short'),
              accessor: (query: PurchaseQuery) =>
                isNil(query?.other_price) || isNaN(query?.other_price) ? '-' : query?.other_price,
              cssClass: 'text-center'
            },
            {
              id: 'other_reason',
              label: dicoLabelsService.getCollectionLabel('other_reason', 'short'),
              accessor: (query: PurchaseQuery) => query?.other_reason || '-'
            },
            {
              id: 'other_link',
              label: dicoLabelsService.getCollectionLabel('other_link', 'short'),
              accessor: (query: PurchaseQuery) => query?.other_link || '-',
              component: 'link-cell'
            }
          ]
        : [
            {
              id: 'code',
              label: dicoLabelsService.getCollectionLabel('code', 'short'),
              accessor: (query: PurchaseQuery) => query?.purchasable?.code || '-'
            },
            {
              id: 'desc',
              label: dicoLabelsService.getCollectionLabel('desc', 'short'),
              accessor: (query: PurchaseQuery) => query?.purchasable?.desc || '-'
            },
            {
              id: 'quantity',
              label: dicoLabelsService.getCollectionLabel('quantity', 'short'),
              accessor: (query: PurchaseQuery) => ({
                quantity: query?.purchasable?.quantity,
                buffer: query?.purchasable?.quantity_buffer,
                stockoutDate: query?.purchasable?.stockout_date
              }),
              component: 'quantity-control',
              cssClass: 'text-center'
            },
            {
              id: 'price_avg',
              label: dicoLabelsService.getCollectionLabel('price_avg', 'short'),
              accessor: (query: PurchaseQuery) =>
                isNil(query?.purchasable?.price_avg) || isNaN(query?.purchasable?.price_avg)
                  ? '-'
                  : query?.purchasable?.price_avg,
              cssClass: 'text-center'
            }
          ];

    columns.push(
      {
        id: 'quantity_query',
        label: dicoLabelsService.getCollectionLabel('quantity_query', 'short'),
        accessor: (query: PurchaseQuery) =>
          isNil(query?.quantity_query) || isNaN(query?.quantity_query) ? '-' : query?.quantity_query,
        cssClass: 'text-center'
      },
      {
        id: 'querier',
        label: dicoLabelsService.getCollectionLabel('querier', 'short'),
        accessor: (query: PurchaseQuery) => dicoLabelsService.formatName(query.querier)
      },
      {
        id: 'query_date',
        label: dicoLabelsService.getCollectionLabel('query_date', 'short'),
        accessor: (query: PurchaseQuery) => (query.created_at ? datesService.format(query.created_at) : '-')
      }
    );

    return columns;
  }

  private listPickingColumns(settings: GlobalSettings): Attribute<Picking>[] {
    if (!settings) return [];

    return [
      {
        id: 'start_picking',
        label: 'Démarrer',
        cssClass: 'text-center',
        accessor: (picking: Picking) => picking.id,
        component: 'btn'
      },
      {
        id: 'cart_number',
        label: dicoLabelsService.getCollectionLabel('cart_number'),
        cssClass: 'text-center',
        accessor: (picking: Picking) => {
          return '# ' + picking.cart_number;
        }
      },
      {
        id: 'computer_type',
        label: dicoLabelsService.getCollectionLabel('computer_type'),
        cssClass: 'text-center',
        accessor: (picking: Picking) => {
          return picking.computer_type == 'laptop' ? 'Ordinateurs portables' : 'Ordinateurs fixes';
        }
      },
      {
        id: 'computer_quantity',
        label: dicoLabelsService.getCollectionLabel('computer_quantity'),
        cssClass: 'text-center',
        accessor: (picking: Picking) => picking.computer_quantity
      },
      {
        id: 'created_at',
        label: 'Date',
        cssClass: 'text-center',
        accessor: (picking: Picking) => {
          return picking.created_at ? datesService.format(picking.created_at) : '-';
        }
      },
      {
        id: 'last_comment',
        label: 'Dernier commentaire',
        accessor: (picking: Picking) => picking.last_comment?.content || '-'
      }
    ];
  }

  private listMarketplaceSkuColumns(settings: GlobalSettings): Attribute<MarketplaceSku>[] {
    if (!settings) return [];

    return [
      {
        id: 'marketplace',
        label: dicoLabelsService.getCollectionLabel('marketplace', 'short'),
        accessor: (marketplaceSku: MarketplaceSku) => marketplaceSku?.marketplace?.display_name || '-'
      },
      {
        id: 'sku_marketplace',
        label: dicoLabelsService.getCollectionLabel('sku_marketplace', 'short'),
        accessor: (marketplaceSku: MarketplaceSku) => marketplaceSku.sku_marketplace || '-'
      },
      {
        id: 'sku_okamac',
        label: dicoLabelsService.getCollectionLabel('sku_okamac', 'short'),
        accessor: (marketplaceSku: MarketplaceSku) => marketplaceSku.sku_okamac || '-'
      },
      {
        id: 'tax_scheme',
        label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short'),
        accessor: (marketplaceSku: MarketplaceSku) => marketplaceSku.tax_scheme || '-'
      }
    ];
  }

  private listUkInvoicesColumns(settings: GlobalSettings): Attribute<UkInvoice>[] {
    return [
      {
        id: 'date',
        label: dicoLabelsService.getCollectionLabel('date', 'short'),
        accessor: (ukInvoice: UkInvoice) => {
          return ukInvoice.created_at ? datesService.format(ukInvoice.created_at, 'Do MMMM YYYY') : '-';
        }
      },
      {
        id: 'amount',
        label: dicoLabelsService.getCollectionLabel('amount', 'short'),
        accessor: (ukInvoice: UkInvoice) =>
          dicoLabelsService.formatAmount(
            isNil(ukInvoice?.total_ttc) || isNaN(ukInvoice?.total_ttc) ? '-' : ukInvoice?.total_ttc,
            Object.fromEntries(settings.currencies),
            'EUR'
          ),
        cssClass: 'text-center'
      },
      {
        id: 'nb_items',
        label: dicoLabelsService.getCollectionLabel('device_count', 'short'),
        accessor: (ukInvoice: UkInvoice) =>
          isNil(ukInvoice?.nb_items) || isNaN(ukInvoice?.nb_items) ? '-' : ukInvoice?.nb_items,
        cssClass: 'text-center'
      }
    ];
  }

  private listUkTransferColums(settings: GlobalSettings): Attribute<UkTransfer>[] {
    return [
      {
        id: 'serial_no',
        label: dicoLabelsService.getCollectionLabel('serial_no', 'short'),
        accessor: (ukTransfer: UkTransfer) => ukTransfer.serial_no || '-'
      },
      {
        id: 'ordered_item_desc',
        label: dicoLabelsService.getCollectionLabel('ordered_item_desc', 'short'),
        sortKeys: ['ordered_item_desc'],
        accessor: (ukTransfer: UkTransfer) => dicoLabelsService.formatItemDesc(ukTransfer, settings),
        cssClass: 'no-wrap'
      },
      {
        id: 'tax_scheme',
        label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short'),
        accessor: (ukTransfer: UkTransfer) => ukTransfer.tax_scheme || '-',
        cssClass: 'text-center'
      },
      {
        id: 'price',
        label: dicoLabelsService.getCollectionLabel('price', 'short'),
        accessor: (ukTransfer: UkTransfer) =>
          dicoLabelsService.formatAmount(
            isNil(ukTransfer?.purchase_price) || isNaN(ukTransfer?.purchase_price) ? '-' : ukTransfer?.purchase_price,
            Object.fromEntries(settings.currencies),
            'EUR'
          ),
        cssClass: 'text-center'
      }
    ];
  }

  private listCageCarrierModalColumns(): Attribute<CageCarrierLabel>[] {
    return [
      {
        id: 'item_code',
        label: dicoLabelsService.getCollectionLabel('serial_no', 'short'),
        accessor: (cageCarrierLabel: CageCarrierLabel) => cageCarrierLabel?.item_code || '-'
      },
      {
        id: 'tracking_number',
        label: dicoLabelsService.getCollectionLabel('shipping_tracking_number'),
        cssClass: 'text-center',
        accessor: (cageCarrierLabel: CageCarrierLabel) => cageCarrierLabel?.tracking_number || '-'
      }
    ];
  }

  private listDeviceKeys(): TableSortingKey[] {
    return [
      {
        value: 'model_namestring',
        label: dicoLabelsService.getCollectionLabel('model_namestring', 'short')
      },
      {
        value: 'size',
        label: dicoLabelsService.getCollectionLabel('size', 'short')
      },
      {
        value: 'year',
        label: dicoLabelsService.getCollectionLabel('year', 'short')
      },
      {
        value: 'proc',
        label: dicoLabelsService.getCollectionLabel('proc', 'short')
      },
      {
        value: 'freq',
        label: dicoLabelsService.getCollectionLabel('freq', 'short')
      },
      {
        value: 'ram',
        label: dicoLabelsService.getCollectionLabel('ram', 'short')
      },
      {
        value: 'hdtype',
        label: dicoLabelsService.getCollectionLabel('hdtype', 'short')
      },
      {
        value: 'hdsize',
        label: dicoLabelsService.getCollectionLabel('hdsize', 'short')
      },
      {
        value: 'grade',
        label: dicoLabelsService.getCollectionLabel('grade', 'short')
      },
      {
        value: 'color',
        label: dicoLabelsService.getCollectionLabel('color', 'short')
      },
      {
        value: 'keyboard',
        label: dicoLabelsService.getCollectionLabel('keyboard', 'short')
      },
      {
        value: 'serial_no',
        label: dicoLabelsService.getCollectionLabel('serial_no', 'short')
      },
      {
        value: 'stock_id',
        label: dicoLabelsService.getCollectionLabel('stock', 'short')
      }
    ];
  }

  private listMacStocksKeys(): TableSortingKey[] {
    return [
      {
        value: 'model_string',
        label: dicoLabelsService.getCollectionLabel('model_namestring', 'short')
      },
      {
        value: 'size',
        label: dicoLabelsService.getCollectionLabel('size', 'short')
      },
      {
        value: 'year',
        label: dicoLabelsService.getCollectionLabel('year', 'short')
      },
      {
        value: 'freq',
        label: dicoLabelsService.getCollectionLabel('freq', 'short')
      },
      {
        value: 'proc',
        label: dicoLabelsService.getCollectionLabel('proc', 'short')
      },
      {
        value: 'ram',
        label: dicoLabelsService.getCollectionLabel('ram', 'short')
      },
      {
        value: 'hdsize',
        label: dicoLabelsService.getCollectionLabel('hdsize', 'short')
      },
      {
        value: 'color',
        label: dicoLabelsService.getCollectionLabel('color', 'short')
      },
      {
        value: 'devices_count',
        label: dicoLabelsService.getCollectionLabel('devices_count', 'short')
      }
    ];
  }

  private listOrderLineKeys(): TableSortingKey[] {
    return [
      {
        value: 'ordered_item_namestring',
        label: dicoLabelsService.getCollectionLabel('ordered_item_namestring', 'short')
      },
      {
        value: 'customer_name',
        label: dicoLabelsService.getCollectionLabel('customer_name', 'short')
      },
      {
        value: 'customer_country',
        label: dicoLabelsService.getCollectionLabel('customer_country', 'short')
      },
      {
        value: 'marketplace',
        label: dicoLabelsService.getCollectionLabel('marketplace', 'short')
      },
      {
        value: 'order_date',
        label: dicoLabelsService.getCollectionLabel('order_date', 'short')
      },
      {
        value: 'max_shipping_date',
        label: dicoLabelsService.getCollectionLabel('max_shipping_date', 'short')
      }
    ];
  }

  private listProductionOrderKeys(): TableSortingKey[] {
    return [
      {
        value: 'model_namestring',
        label: dicoLabelsService.getCollectionLabel('model_namestring', 'short')
      },
      {
        value: 'size',
        label: dicoLabelsService.getCollectionLabel('size', 'short')
      },
      {
        value: 'year',
        label: dicoLabelsService.getCollectionLabel('year', 'short')
      },
      {
        value: 'proc',
        label: dicoLabelsService.getCollectionLabel('proc', 'short')
      },
      {
        value: 'freq',
        label: dicoLabelsService.getCollectionLabel('freq', 'short')
      },
      {
        value: 'ram',
        label: dicoLabelsService.getCollectionLabel('ram', 'short')
      },
      {
        value: 'hdtype',
        label: dicoLabelsService.getCollectionLabel('hdtype', 'short')
      },
      {
        value: 'hdsize',
        label: dicoLabelsService.getCollectionLabel('hdsize', 'short')
      },
      {
        value: 'grade',
        label: dicoLabelsService.getCollectionLabel('grade', 'short')
      },
      {
        value: 'color',
        label: dicoLabelsService.getCollectionLabel('color', 'short')
      }
    ];
  }

  private listPurchaseKeys(): TableSortingKey[] {
    return [
      {
        value: 'amount',
        label: dicoLabelsService.getCollectionLabel('amount', 'short')
      },
      {
        value: 'quantity',
        label: dicoLabelsService.getCollectionLabel('quantity', 'short')
      },
      {
        value: 'warranty',
        label: dicoLabelsService.getCollectionLabel('warranty', 'short')
      }
    ];
  }

  private listSpareKeys(): TableSortingKey[] {
    return [
      {
        value: 'code',
        label: dicoLabelsService.getCollectionLabel('code', 'short')
      },
      {
        value: 'desc',
        label: dicoLabelsService.getCollectionLabel('desc', 'short')
      },
      {
        value: 'quantity',
        label: dicoLabelsService.getCollectionLabel('quantity', 'short')
      },
      {
        value: 'quantity_desired',
        label: dicoLabelsService.getCollectionLabel('quantity_desired', 'short')
      },
      {
        value: 'price_avg',
        label: dicoLabelsService.getCollectionLabel('price_avg', 'short')
      }
    ];
  }

  private listSupplierKeys(): TableSortingKey[] {
    return [
      {
        value: 'display_name',
        label: dicoLabelsService.getCollectionLabel('display_name', 'short')
      },
      {
        value: 'country',
        label: dicoLabelsService.getCollectionLabel('country', 'short')
      },
      {
        value: 'currency',
        label: dicoLabelsService.getCollectionLabel('currency', 'short')
      },
      {
        value: 'tax_scheme',
        label: dicoLabelsService.getCollectionLabel('tax_scheme', 'short')
      },
      {
        value: 'warranty',
        label: dicoLabelsService.getCollectionLabel('warranty', 'short')
      },
      {
        value: 'payment_timing',
        label: dicoLabelsService.getCollectionLabel('payment_timing', 'short')
      }
    ];
  }

  private listSpecialProcessPriorityKeys(): TableSortingKey[] {
    return [
      {
        value: 'year',
        label: dicoLabelsService.getCollectionLabel('year', 'short')
      },
      {
        value: 'size',
        label: dicoLabelsService.getCollectionLabel('size', 'short')
      },
      {
        value: 'model_namestring',
        label: dicoLabelsService.getCollectionLabel('model_namestring', 'short')
      },
      {
        value: 'aspiring_grade',
        label: 'mdr'
      }
    ];
  }

  private listConsumableKeys(): TableSortingKey[] {
    return [
      {
        value: 'code',
        label: dicoLabelsService.getCollectionLabel('code', 'short')
      },
      {
        value: 'desc',
        label: dicoLabelsService.getCollectionLabel('desc', 'short')
      },
      {
        value: 'quantity_supplier',
        label: dicoLabelsService.getCollectionLabel('quantity_supplier', 'short')
      },
      {
        value: 'quantity',
        label: dicoLabelsService.getCollectionLabel('quantity', 'short')
      },
      {
        value: 'quantity_desired',
        label: dicoLabelsService.getCollectionLabel('quantity_desired', 'short')
      },
      {
        value: 'price_avg',
        label: dicoLabelsService.getCollectionLabel('price_avg', 'short')
      }
    ];
  }
  private listPartKeys(): TableSortingKey[] {
    return [
      {
        value: 'serial_number',
        label: dicoLabelsService.getCollectionLabel('serial_number', 'short')
      },
      {
        value: 'part_type',
        label: dicoLabelsService.getCollectionLabel('part_type', 'short')
      },
      {
        value: 'color',
        label: dicoLabelsService.getCollectionLabel('color', 'short')
      }
    ];
  }
  private listMarketplaceSkuKeys(): TableSortingKey[] {
    return [
      {
        value: 'marketplace',
        label: dicoLabelsService.getCollectionLabel('marketplace', 'short')
      }
    ];
  }

  private listOrderReturnKeys(): TableSortingKey[] {
    return [
      {
        value: 'process_validated_at',
        label: dicoLabelsService.getCollectionLabel('shipping_date', 'short')
      }
    ];
  }

  private listDispatchCagesKeys(): TableSortingKey[] {
    return [
      {
        value: 'created_at',
        label: dicoLabelsService.getCollectionLabel('created_at')
      },
      {
        value: 'state',
        label: dicoLabelsService.getCollectionLabel('state')
      },
      {
        value: 'picked_up_at',
        label: dicoLabelsService.getCollectionLabel('picked_up_at')
      }
    ];
  }

  private listCommissionsKeys(): TableSortingKey[] {
    return [
      {
        value: 'rate',
        label: dicoLabelsService.getCollectionLabel('rate')
      },
      {
        value: 'fixed_amount',
        label: dicoLabelsService.getCollectionLabel('fixed_amount')
      }
    ];
  }
}

export const tablesService = new TablesService();
