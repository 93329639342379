import { Comment } from '@/services/comments/comments.types';
import { Device } from '@/services/devices/devices.types';
import { OrderLine } from '@/services/order-lines/order-lines.types';
import { ProductionOrder } from '@/services/production-orders/production-orders.types';
import { AppSectionCode } from '../app-sections/app-sections.types';
import { ProcessStep } from '../process-steps/process-steps.types';
import { TablePaginationOptions } from '../tables/tables.types';

export enum MatchStatus {
  NOT_MATCHED = 'not_matched',
  DONES = 'dones',
  MATCHED = 'matched',
  DISPATCHED = 'dispatched',
  RETRACTED = 'retracted'
}

export interface Match {
  id: number;
  device: Device;
  order_line: OrderLine;
  order_id: number;
  ordered_device: Device;
  picked: boolean;
  comments: Comment[];
  steps: ProcessStep[];
  match_errors?: string;
  alert?: string;
  production_order: ProductionOrder;
  order_line_id: number;
  item_id: number;
}

export interface MatchesListOptions {
  pagination?: TablePaginationOptions;
  process?: AppSectionCode;
}

export interface ReportMatchErrorOptions {
  stockId: number;
  stepCode: string;
  comment: string;
  dissociate: boolean;
}

export interface SkuDifferenceCostImportOptions {
  file: File;
}

export interface SkuDifferenceCostImportResponse {
  count: number;
}

export interface RepairCostImportOptions {
  count: File;
}

export interface RepairCostImportResponse {
  count: number;
}

export interface ImportUpdateStatusOptions {
  file: File;
}

export type MatchesListApiResponse = Match[] | string;
