import { Module } from 'vuex';
import { RootState } from '@/store';
import { ItemAssociates, PurchaseItems } from '@/services/purchase-items/purchase-items.types';
import { purchaseitemsService } from '@/services/purchase-items/purchase-items.service';
import { Device } from '@/services/devices/devices.types';
import { PurchaseItemCalcul } from '@/services/purchases/purchases.types';
import { purchasesService } from '@/services/purchases/purchases.service';

export interface PurchaseItemsState {
  purchaseItems: PurchaseItems | null;
  purchaseItemCalcul: PurchaseItemCalcul;
  purchaseItemsList: PurchaseItems[];
  purchaseItemsLoading: boolean;
}

const state: PurchaseItemsState = {
  purchaseItems: null,
  purchaseItemCalcul: null,
  purchaseItemsList: [],
  purchaseItemsLoading: false
};

const purchaseItemsModule: Module<PurchaseItemsState, RootState> = {
  namespaced: true,
  state,
  actions: {
    setPurchaseItems({ commit, dispatch }, purchaseItems: PurchaseItems | string | number): Promise<void> {
      if (typeof purchaseItems === 'number' || typeof purchaseItems === 'string') {
        return dispatch('getPurchaseItems', purchaseItems);
      } else {
        commit('setPurchaseItems', purchaseItems);
        return Promise.resolve();
      }
    },
    getPurchaseItems({ commit, dispatch }, id: number | string): Promise<void> {
      commit('purchaseItemsLoading', true);
      return purchaseitemsService
        .get(+id)
        .then((purchaseItems: PurchaseItems | null) => {
          if (purchaseItems) {
            commit('setPurchaseItems', purchaseItems);
          }
        })
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('purchaseItemsLoading', false);
        });
    },
    associateItem({ commit, dispatch }, Itemassociate: ItemAssociates) {
      commit('purchaseItemsLoading', true);
      return purchaseitemsService
        .associateItem(Itemassociate)
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('purchaseItemsLoading', false);
        });
    },
    createPurchaseItem: ({ commit, dispatch }, { reference, device }: { reference: string; device }): Promise<void> => {
      commit('purchaseItemsLoading', true);
      return purchasesService
        .createPurchaseItem(reference, device)
        .then(purchaseItemCalcul => purchaseItemCalcul && commit('purchaseItemCalcul', purchaseItemCalcul))
        .then(() => dispatch('alert/pushSuccess', 'Ligne créée avec succès !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('purchaseItemsLoading', false));
    },
    getCandidatePurchaseItem({ commit, dispatch }, device: Device) {
      commit('purchaseItemsLoading', true);
      return purchaseitemsService
        .getCandidatePurchaseItem(device)
        .then(purchaseItemsList => commit('purchaseItemsList', purchaseItemsList))
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('purchaseItemsLoading', false);
        });
    },
    completeAssociate({ commit, dispatch }, devicesSelect) {
      commit('purchaseItemsLoading', true);
      return purchaseitemsService
        .completeAssociate(devicesSelect)
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('purchaseItemsLoading', false);
        });
    }
  },
  mutations: {
    setPurchaseItems(state, purchaseItems: PurchaseItems | null) {
      state.purchaseItems = purchaseItems;
    },
    purchaseItemCalcul(state, purchaseItemCalcul: PurchaseItemCalcul) {
      state.purchaseItemCalcul = purchaseItemCalcul;
    },
    purchaseItemsList(state, purchaseItemsList: PurchaseItems[]) {
      state.purchaseItemsList = purchaseItemsList;
    },
    purchaseItemsLoading(state, loading: boolean) {
      state.purchaseItemsLoading = loading;
    }
  },
  getters: {}
};

export default purchaseItemsModule;
