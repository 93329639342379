import { Module } from 'vuex';

import { RootState } from '@/store';
import { SupplierGrade } from '@/services/suppliers-grade/suppliers-grade.types';
import { supplierGradesService } from '@/services/suppliers-grade/suppliers-grade.service';
import { formsService } from '@/services/forms/forms.service';

export interface SupplierGradeState {
  supplierGrade: SupplierGrade;
  supplierGradeList: SupplierGrade[];
  supplierGradeLoading: boolean;
}

const state: SupplierGradeState = {
  supplierGrade: null,
  supplierGradeList: [],
  supplierGradeLoading: false
};

const supplierGradeModule: Module<SupplierGradeState, RootState> = {
  namespaced: true,
  state,
  actions: {
    setSupplierGrade: ({ commit }, supplierGrade: SupplierGrade): void => {
      commit('supplierGrade', supplierGrade);
    },
    getSupplierGradeList: ({ commit, dispatch }, params: { supplier_id: number; model: string }): Promise<void> => {
      commit('supplierGradeLoading', true);
      return supplierGradesService
        .list(params.supplier_id, params.model)
        .then(supplierGradeList => supplierGradeList && commit('supplierGradeList', supplierGradeList))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierGradeLoading', false));
    },
    createSupplierGrade: ({ commit, dispatch }, supplierGrade: SupplierGrade): Promise<void> => {
      commit('supplierGradeLoading', true);
      return supplierGradesService
        .create(supplierGrade)
        .then(supplierGrade => supplierGrade && commit('supplierGrade', supplierGrade))
        .then(() => dispatch('alert/pushSuccess', 'Grade fournisseur créé !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierGradeLoading', false));
    },
    updateSupplierGrade: ({ commit, dispatch }, supplierGrade: SupplierGrade): Promise<void> => {
      commit('supplierGradeLoading', true);
      return supplierGradesService
        .update(supplierGrade.id, supplierGrade)
        .then(supplierGrade => supplierGrade && commit('supplierGrade', supplierGrade))
        .then(() => dispatch('alert/pushSuccess', 'Grade fournisseur mise à jour !', { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('supplierGradeLoading', false));
    }
  },
  mutations: {
    supplierGrade: (state, supplierGrade: SupplierGrade) => (state.supplierGrade = supplierGrade),
    supplierGradeList: (state, supplierGradeList: SupplierGrade[]) => (state.supplierGradeList = supplierGradeList),
    supplierGradeLoading: (state, supplierGradeLoading: boolean) => (state.supplierGradeLoading = supplierGradeLoading)
  },
  getters: {
    supplierGradeForm: (state, getters, rootState) => {
      return formsService.getForm(
        'supplier-grade',
        state.supplierGrade,
        rootState['global-settings'].settings,
        state.supplierGradeList
      );
    }
  }
};

export default supplierGradeModule;
