import { Module } from 'vuex';

import { failuresService } from '@/services/failures/failures.service';
import { Failure, FailureItem, StructureType } from '@/services/failures/failures.types';
import { RootState } from '@/store';

export interface FailureState {
  failureList: Failure[];
  firstLevel: Failure[];
  childList: Failure[];
  navMenuLoading: boolean;
  selectedFailure: Failure;
  failureItemList: FailureItem[];
  failureLoading: boolean;
  structureTypeList: FailureItem[];
}

const state: FailureState = {
  failureList: [],
  firstLevel: [],
  childList: [],
  navMenuLoading: false,
  selectedFailure: null,
  failureItemList: [],
  failureLoading: false,
  structureTypeList: [
    {
      disabled: false,
      name: 'MacBook',
      id: 0,
      selected: false,
      status: null,
      failure_photos: []
    },
    {
      disabled: false,
      name: 'iMac',
      id: 0,
      selected: false,
      status: null,
      failure_photos: []
    },
    {
      disabled: false,
      name: 'MacMini / MacPro / MacStudio',
      id: 0,
      selected: false,
      status: null,
      failure_photos: []
    }
  ]
};

const options: Module<FailureState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    getFirstLevel: ({ commit, dispatch }, structureType: StructureType): Promise<void> => {
      commit('navMenuLoading', true);
      return failuresService
        .getFirstLevel(structureType)
        .then(firstLevel => firstLevel && commit('firstLevel', firstLevel))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('navMenuLoading', false));
    },
    getChilds: ({ commit, dispatch }, failure: Failure): Promise<void> => {
      commit('navMenuLoading', true);
      return failuresService
        .getChilds(failure.id)
        .then(childs => {
          if (childs.length == 0) dispatch('addFailureList', failure);
          else childs && commit('childList', childs), dispatch('setSelectedFailure', failure.id);
        })
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('navMenuLoading', false));
    },
    getFailureStatement: ({ commit, dispatch }, payload: { level: number; modelSelected: string }): Promise<void> => {
      commit('failureLoading', true);
      const { level, modelSelected } = payload;
      return failuresService
        .getFailureStatement(level, modelSelected)
        .then(list => {
          return list && commit('failureList', list);
        })
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('failureLoading', false));
    },
    setFailureList: ({ commit, dispatch }, payload: { serial_no: string; full: boolean }): Promise<void> => {
      const { serial_no, full } = payload;
      commit('failureLoading', true);
      return failuresService
        .failureItemList(serial_no, full)
        .then(list => list && commit('failureItemList', list))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('failureLoading', false));
    },
    addFailureList: ({ dispatch }, item: Failure): void => {
      dispatch('setSelectedFailure', item.id).then(() =>
        dispatch('generateFailureItem', state.selectedFailure).then(() => dispatch('resetFilter'))
      );
    },
    deleteFailureList: ({ commit }, index: number): void => {
      commit('deleteFailureList', index);
    },
    setSelectedFailure: ({ commit, dispatch }, failureId: number): Promise<void> => {
      commit('failureLoading', true);
      return failuresService
        .getFailureById(failureId)
        .then(failure => failure && commit('selectedFailure', failure))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('failureLoading', false));
    },
    navBack: ({ dispatch, state }): void => {
      if (state.selectedFailure.parent_id != null) {
        dispatch('setSelectedFailure', state.selectedFailure.parent_id).then(() => {
          dispatch('getChilds', state.selectedFailure);
        });
      } else {
        dispatch('resetFilter');
      }
    },

    parseDeviceFailures: ({ commit, dispatch }, deviceFailureId: number): Promise<void> => {
      commit('failureLoading', true);
      return failuresService
        .getParsedName(deviceFailureId)
        .then(failure => failure && commit('addFailureList', failure))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('failureLoading', false));
    },

    generateFailureItem: ({ commit, dispatch }, failure: Failure): void => {
      const suiteFailure: Failure[] = [];
      suiteFailure.push(failure);

      function parseName(suite: Failure[]) {
        const itemName: string[] = [];
        let itemId: number;
        for (let index = 0; index < suite.length; index++) {
          itemName.push(suite[index].name);
          itemId = suite[index].id;
        }
        const item: FailureItem = {
          name: itemName.join(' | '),
          id: itemId,
          disabled: false,
          selected: false,
          status: null,
          failure_photos: []
        };
        return item;
      }

      if (failure.parent_id != null) {
        dispatch('setSelectedFailure', failure.parent_id).then(() => {
          suiteFailure.unshift(state.selectedFailure);
          if (state.selectedFailure.parent_id != null) {
            dispatch('setSelectedFailure', state.selectedFailure.parent_id).then(() => {
              suiteFailure.unshift(state.selectedFailure);
              commit('addFailureList', parseName(suiteFailure));
            });
          } else commit('addFailureList', parseName(suiteFailure));
        });
      } else commit('addFailureList', parseName(suiteFailure));
    },

    selectFailureItem: ({ commit }, index: number): void => {
      commit('selectFailureItem', index);
    },

    addFailurePhotos: ({ commit }, payload: { item: FailureItem; failure_photos: File | string }): void => {
      commit('addFailurePhotos', payload);
    },
    setSelectedFailureWithFailure: ({ commit }, failure: Failure): void => {
      commit('selectedFailure', failure);
    },
    deleteFailurePhotos: ({ commit }, payload: { item: FailureItem; failure_photos: File | string }): void => {
      commit('deleteFailurePhotos', payload);
    },

    cancelSelectedFailureItem: ({ commit }): void => {
      for (let index = 0; index < state.failureItemList.length; index++) {
        if (state.failureItemList[index].selected == true) commit('selectFailureItem', index);
      }
    },

    activeFailure: ({ commit }, failure: Failure): void => {
      commit('activeFailure', failure);
    },

    // CLEAR STATE
    resetFilter: ({ dispatch }): void => {
      dispatch('clearChildList');
      dispatch('clearSelectedFailure');
    },
    clearFailureList: ({ commit }): void => {
      commit('failureList', []);
    },
    clearFirstLevel: ({ commit }): void => {
      commit('firstLevel', []);
    },
    clearChildList: ({ commit }): void => {
      commit('childList', []);
    },
    clearSelectedFailure: ({ commit }): void => {
      commit('selectedFailure', null);
    },
    clearFailureItemList: ({ commit }): void => {
      commit('failureItemList', []);
    },
    clearFailureListFrom: ({ commit }, index: number): void => {
      const len = state.failureItemList.length;
      for (let i = 0; i < len - index; i++) {
        commit('deleteFailureList', index);
      }
    }
  },
  mutations: {
    failureList: (state, list: Failure[]) => (state.failureList = list),
    firstLevel: (state, list: Failure[]) => (state.firstLevel = list),
    childList: (state, list: Failure[]) => (state.childList = list),
    navMenuLoading: (state, loading: boolean) => (state.navMenuLoading = loading),
    failureItemList: (state, itemList: FailureItem[]) => (state.failureItemList = itemList),
    selectedFailure: (state, failure: Failure) => (state.selectedFailure = failure),
    failureLoading: (state, loading: boolean) => (state.failureLoading = loading),
    addFailurePhotos: (state, payload: { item; failure_photos }) =>
      payload.item.failure_photos.push(payload.failure_photos),
    deleteFailurePhotos: (state, payload: { item; failure_photos }) =>
      payload.item.failure_photos.splice(payload.item.failure_photos.indexOf(payload.failure_photos), 1),
    selectFailureItem: (state, index: number) =>
      (state.failureItemList[index].selected = !state.failureItemList[index].selected),

    addFailureList: (state, failure: FailureItem) => state.failureItemList.push(failure),
    deleteFailureList: (state, index: number) => state.failureItemList.splice(index, 1)
  }
};

export default options;
