import { Module } from 'vuex';

import { unpackingService } from '@/services/unpacking/unpacking.services';
import { Unpacking } from '@/services/unpacking/unpacking.types';
import { RootState } from '@/store';

export interface UnpackingState {
  unpacking: Unpacking;
  unpackingLoading: boolean;
}

const state: UnpackingState = {
  unpacking: null,
  unpackingLoading: false
};
// const state: UnpackingState = {
//   unpacking: null,
//   unpackingLoading: false
// };

const options: Module<UnpackingState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    getUnpackingWithPurchase: ({ commit, dispatch }, purchase_id: number): Promise<void> => {
      commit('unpackingLoading', true);
      return unpackingService
        .getUnpackingWithPurchase(purchase_id)
        .then(unpacking => unpacking && commit('unpacking', unpacking))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('unpackingLoading', false));
    },
    printNextUnpackingLabel: ({ commit, dispatch }): Promise<void> => {
      commit('unpackingLoading', true);
      return unpackingService
        .nextIncrementValue(state.unpacking.id)
        .then(data => {
          data.zpl && dispatch('printer/printWith', { data: [data.zpl], printer: 'MINI' }, { root: true });
          commit('unpacking', data.unpacking);
        })
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('unpackingLoading', false));
    },
    printCurrentUnpackingLabel: ({ commit, dispatch }, incrementValue): Promise<void> => {
      commit('unpackingLoading', true);
      return unpackingService
        .currentIncrementValue(state.unpacking.id, incrementValue)
        .then(data => data.zpl && dispatch('printer/printWith', { data: [data.zpl], printer: 'MINI' }, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('unpackingLoading', false));
    }
  },
  // namespaced: true,
  // state: () => state,
  // actions: {},
  // mutations: {
  //   grade: (state, grade: Grade) => (state.grade = grade),
  //   gradeLoading: (state, loading: boolean) => (state.gradeLoading = loading)
  // },
  mutations: {
    unpackingLoading: (state, unpackingLoading) => (state.unpackingLoading = unpackingLoading),
    unpacking: (state, unpacking) => (state.unpacking = unpacking)
  }
};

export default options;
