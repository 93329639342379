import { Module } from 'vuex';

import { repairCostsService } from '@/services/repair-costs/repair-costs.service';
import { RepairCost } from '@/services/repair-costs/repair-costs.types';
import { RootState } from '@/store';

export interface RepairCostState {
  ordersImporting: any;
  RepairCostsLoading: boolean;
  RepairCosts: RepairCost[];
}

const state: RepairCostState = {
  RepairCostsLoading: false,
  RepairCosts: [],
  ordersImporting: undefined
};

const options: Module<RepairCostState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    importRepairCost: ({ commit, dispatch }, { file }: { file: File }): Promise<void> => {
      return repairCostsService
        .importRepairCost(file)
        .then(resp =>
          dispatch(
            'alert/pushSuccess',
            `${resp.count} modification${resp.count > 1 ? 's' : ''} importée${resp.count > 1 ? 's' : ''} !`,
            { root: true }
          )
        )
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('ordersImporting', false));
    }
  },
  mutations: {
    ordersImporting: (state, importing) => (state.ordersImporting = importing)
  }
};

export default options;
