import axios from '@/core/axios';

import { Failure, FailureItem, StructureType } from './failures.types';

export class FailuresService {
  getFirstLevel(structurType: StructureType): Promise<Failure> {
    return axios.get(`/failures/first_level/${structurType}`).then(resp => resp.data);
  }

  getChilds(failureId: number): Promise<Failure[]> {
    if (!failureId) throw new Error('Missing failure id in getChilds');
    return axios.get<Failure[]>(`/failures/${failureId}/get_childs`).then(resp => resp.data);
  }

  getFailureById(failureId: number): Promise<Failure> {
    if (!failureId) throw new Error('Missing failure_id in getFailureById');
    return axios.get<Failure>(`/failures/${failureId}/get_failure_by_id`).then(resp => resp.data);
  }

  getNextFailure(failureId: number): Promise<Failure> {
    if (!failureId) throw new Error('Missing failure_id in getNextFailure');
    return axios.get<Failure>(`/failures/${failureId}/next_failure`).then(resp => resp?.data);
  }

  getParsedName(failureId: number): Promise<Failure> {
    if (!failureId) throw new Error('Missing failureId in getParsedName');
    return axios.get<Failure>(`/failures/${failureId}/get_parsed_name`).then(resp => resp?.data);
  }

  getFailureStatement(level, model_selected): Promise<FailureItem[]> {
    return axios.post<FailureItem[]>(`/failures/failure_statement`, { level, model_selected }).then(resp => resp?.data);
  }

  failureItemList(serial_no: string, full: boolean): Promise<FailureItem[]> {
    if (!serial_no) throw new Error('Missing serial number in failureItemList');
    return axios.post<FailureItem[]>(`/failures/${serial_no}/failure_item_list`, { full }).then(resp => resp?.data);
  }
}

export const failuresService = new FailuresService();
