import axios from '@/core/axios';

import { CollectionList } from '../collections/collections.types';
import { ItemAssociates, PurchaseItems, PurchaseItemsListOptions } from './purchase-items.types';
import { Device } from '../devices/devices.types';

export class PurchaseItemsService {
  list(opts: PurchaseItemsListOptions = {}): Promise<CollectionList<'purchase-items'>> {
    const { filters, pagination } = opts;

    return axios
      .get<CollectionList<'purchase-items'>>(`/purchase-items`, { params: { ...filters, ...pagination } })
      .then(resp => resp?.data);
  }
  get(purchaseItemsId: number): Promise<PurchaseItems> {
    return axios.get<PurchaseItems>(`/purchase-items/${purchaseItemsId}`).then(resp => resp?.data);
  }
  update(purchaseItemsId: number | string): Promise<PurchaseItems> {
    if (!purchaseItemsId) throw new Error('Missing production order id in update');
    return axios.put<PurchaseItems>(`purchase-items/${purchaseItemsId}`).then(resp => resp?.data);
  }
  associateItem(itemAssociates: ItemAssociates): Promise<void> {
    return axios.post<void>(`purchase-items/associate`, { itemAssociate: itemAssociates }).then(resp => resp?.data);
  }
  getCandidatePurchaseItem(device: Device): Promise<PurchaseItems[]> {
    return axios.post<PurchaseItems[]>(`purchase-items/get-candidate`, { device: device }).then(resp => resp?.data);
  }
  completeAssociate(devicesSelect): Promise<void> {
    return axios
      .post<void>(`purchase-items/complete-associate`, {
        devicesSelect: devicesSelect
      })
      .then(resp => resp?.data);
  }
}

export const purchaseitemsService = new PurchaseItemsService();
