import { Module } from 'vuex';
import { RootState } from '@/store';
import { RmaElement } from '@/services/rma-element/rma-element.types';
import { rmaElementsService } from '@/services/rma-element/rma-element.service';

export interface RmaElementsState {
  rmaElementsList: RmaElement[];
  rmaElementLoading: boolean;
}

const state: RmaElementsState = {
  rmaElementsList: [],
  rmaElementLoading: false
};

const purchaseItemsModule: Module<RmaElementsState, RootState> = {
  namespaced: true,
  state,
  actions: {
    getRmaElementList({ commit, dispatch }, device_id: number): Promise<void> {
      commit('rmaElementLoading', true);
      return rmaElementsService
        .list(device_id)
        .then(rmaElementsList => {
          if (rmaElementsList.length > 0) {
            commit('rmaElementsList', rmaElementsList);
          }
        })
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('rmaElementLoading', false);
        });
    },
    selectedItem({ commit, dispatch }, rmaElementId: number): Promise<void> {
      commit('rmaElementsSelected', rmaElementId);
      return rmaElementsService
        .selected(rmaElementId)
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('rmaElementLoading', false);
        });
    },
    addRmaElement({ commit, dispatch }, rmaElement: RmaElement): Promise<void> {
      return rmaElementsService
        .add(rmaElement)
        .then(rmaElementsList => {
          if (rmaElementsList.length > 0) commit('rmaElementsList', rmaElementsList);
        })
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('rmaElementLoading', false);
        });
    },
    editRmaElement({ commit, dispatch }, rmaElement: RmaElement): Promise<void> {
      return rmaElementsService
        .edit(rmaElement)
        .then(rmaElementsList => {
          if (rmaElementsList.length > 0) commit('rmaElementsList', rmaElementsList);
        })
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('rmaElementLoading', false);
        });
    },
    deleteRmaElement({ commit, dispatch }, rmaElementId: number): Promise<void> {
      return rmaElementsService
        .delete(rmaElementId)
        .then(rmaElementsList => {
          if (rmaElementsList.length > 0) commit('rmaElementsList', rmaElementsList);
        })
        .catch(error => {
          dispatch('alert/pushError', error, { root: true });
        })
        .finally(() => {
          commit('rmaElementLoading', false);
        });
    }
  },
  mutations: {
    rmaElementsList(state, rmaElementsList: RmaElement[]) {
      state.rmaElementsList = rmaElementsList;
    },
    rmaElementLoading(state, loading: boolean) {
      state.rmaElementLoading = loading;
    },
    rmaElementsSelected(state, rmaElementId: number) {
      state.rmaElementsList
        .filter(rmaElement => rmaElement.id == rmaElementId)
        .map(rmaElement => (rmaElement.select = !rmaElement.select));
    }
  }
};

export default purchaseItemsModule;
