import axios from '@/core/axios';
import { omitBy } from 'lodash';

import {
  PurchasesListOptions,
  Purchase,
  PurchasePauseOptions,
  PurchasePaymentScheduleOptions,
  PurchasePaymentEffectiveOptions,
  PurchaseRMACreationOptions,
  PurchaseItemsFix,
  PurchaseMessage
} from './purchases.types';
import { CollectionList } from '../collections/collections.types';
import { PurchaseItems } from '../purchase-items/purchase-items.types';

export class PurchasesService {
  list(opts: PurchasesListOptions = {}): Promise<CollectionList<'purchases'>> {
    const { filters, pagination, options } = opts;

    return axios
      .get<CollectionList<'purchases'>>(`/purchases`, {
        params: { ...filters, ...pagination, ...options }
      })
      .then(resp => resp?.data);
  }

  get(purchaseId: number): Promise<Purchase> {
    return axios.get<Purchase>(`/purchases/${purchaseId}`).then(resp => resp?.data);
  }

  candidatePurchaseItem(reference: string, device): Promise<Purchase> {
    return axios.post<Purchase>(`/purchases/${reference}/candidate-purchase-item`, { device }).then(resp => resp?.data);
  }

  deal(purchaseId: number): Promise<Purchase> {
    return axios
      .put<Purchase>(`/purchases/${purchaseId}/deal`)
      .then(resp => {
        return resp?.data;
      })
      .catch(error => {
        console.error('Error dealing purchase:', error.response.data);
        throw new Error(error.response.data.message || 'Error dealing purchase');
      });
  }

  split(params: { purchaseId: number; listPurchase: Purchase[] }): Promise<Purchase> {
    const { purchaseId, listPurchase } = params;
    return axios
      .put(`/purchases/${purchaseId}/split`, { listPurchase: listPurchase })
      .then(resp => {
        return resp.data;
      })
      .catch(error => {
        console.error('Error splitting purchase:', error.response?.data);
        throw new Error(error.response?.data.message || 'Error splitting purchase');
      });
  }

  new(): Promise<Purchase> {
    return axios.get<Purchase>(`/purchases/new`).then(resp => resp?.data);
  }

  create(purchase: Purchase): Promise<Purchase> {
    return axios.post<Purchase>(`/purchases`, { purchase }).then(resp => resp?.data);
  }

  update(purchaseId: number | string, purchase: Partial<Purchase>): Promise<Purchase> {
    if (!purchaseId) {
      console.error('Error: Missing purchase id in update');
      throw new Error('Missing purchase id in update');
    }

    // Filtre les fichiers ou données non pertinentes
    const purchaseWithoutFiles = omitBy(purchase, (value, key) => /_file$/.test(key));

    return axios
      .put<Purchase>(`/purchases/${purchaseId}`, { purchase: purchaseWithoutFiles })
      .then(resp => resp?.data)
      .catch(error => {
        const errorMessage = error.response?.data?.message || 'Error updating purchase';
        console.error('API error:', errorMessage, { response: error.response });
        throw new Error(errorMessage);
      });
  }

  updateFiles(purchaseId: number | string, files: Record<string, File>): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in updateFiles');
    const formData = Object.entries(files).reduce((prev, curr) => {
      prev.append(`purchase[${curr[0]}]`, curr[1]);
      return prev;
    }, new FormData());

    return axios
      .put<Purchase>(`purchases/${purchaseId}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(resp => ('analysis_supplier_file' in files ? this.analyze(purchaseId) : resp?.data));
  }

  addPhotos(purchaseId: number, photo: Record<string, File>): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in addPhotos');
    const formData = Object.entries(photo).reduce((prev, curr) => {
      prev.append(`purchase[${curr[0]}]`, curr[1]);
      return prev;
    }, new FormData());

    return axios
      .put<Purchase>(`purchases/${purchaseId}/photo`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then(resp => resp?.data);
  }

  deletePhoto(purchaseId: number, photo: string, category_photo: string): Promise<Purchase> {
    return axios
      .post<Purchase>(`/purchases/${purchaseId}/delete-photo`, {
        params: { photo: photo, category_photo: category_photo }
      })
      .then(resp => resp?.data);
  }

  createPurchaseItem(reference: string, device: PurchaseItems): Promise<Purchase> {
    return axios
      .post<Purchase>(`/purchases/${reference}/create-purchase-item`, {
        device: device
      })
      .then(resp => resp?.data);
  }

  fixAnalysisSupplierFile(purchaseId: number | string, files: Record<string, File>): Promise<PurchaseItemsFix> {
    if (!purchaseId) throw new Error('Missing purchase id in fixAnalysisSupplierFile');

    const formData = Object.entries(files).reduce((prev, curr) => {
      prev.append(`[${curr[0]}]`, curr[1]);
      return prev;
    }, new FormData());

    return axios
      .post(`purchases/${purchaseId}/fix_analysis_supplier_file`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  pause(purchaseId: number | string, params: PurchasePauseOptions, pause: boolean): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in pause');

    return axios
      .post<Purchase>(`purchases/${purchaseId}/${pause ? 'pause' : 'depause'}`, { params })
      .then(resp => resp?.data);
  }

  analyze(purchaseId: number | string): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in analyze');

    return axios.post<Purchase>(`purchases/${purchaseId}/analyze`).then(resp => resp?.data);
  }

  received(purchaseId: number | string, purchase: Partial<Purchase>): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in analyze');

    return axios.post<Purchase>(`purchases/${purchaseId}/received`, { purchase: purchase }).then(resp => resp?.data);
  }

  progress_reserve(purchaseId: number): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in analyze');

    return axios.post<Purchase>(`purchases/${purchaseId}/progress-reserve`).then(resp => resp?.data);
  }

  finish_reserve(purchaseId: number): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in analyze');

    return axios.post<Purchase>(`purchases/${purchaseId}/finish-reserve`).then(resp => resp?.data);
  }

  schedule(purchaseId: number | string, params: PurchasePaymentScheduleOptions): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in schedule');

    return axios.post<Purchase>(`purchases/${purchaseId}/schedule`, { params }).then(resp => resp?.data);
  }

  createRMA(purchaseId: number | string, params: PurchaseRMACreationOptions): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in createRMA');

    return axios.post<Purchase>(`purchases/${purchaseId}/create-rma`, { params }).then(resp => resp?.data);
  }

  pay(purchaseId: number | string, params: PurchasePaymentEffectiveOptions): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in pay');

    return axios.post<Purchase>(`purchases/${purchaseId}/pay`, { params }).then(resp => resp?.data);
  }

  control(purchaseId: number | string): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in control');

    return axios.post<Purchase>(`purchases/${purchaseId}/control`).then(resp => resp?.data);
  }

  validate(purchaseId: number | string): Promise<Purchase> {
    if (!purchaseId) throw new Error('Missing purchase id in validate');

    return axios.post<Purchase>(`purchases/${purchaseId}/validate`).then(resp => resp?.data);
  }

  delete(purchaseId: number): Promise<void> {
    if (!purchaseId) throw new Error('Missing purchase id in delete');
    return axios.delete<void>(`/purchases/${purchaseId}`).then(resp => resp?.data);
  }

  getPurchaseUnpacking(): Promise<Purchase> {
    return axios.get<Purchase>(`/purchases/unpacking`).then(resp => resp?.data);
  }

  exportDevices(purchaseId: number | string): Promise<void> {
    if (!purchaseId) throw new Error('Missing purchase id in delete');
    return axios
      .post<void>(`/purchases/export/${purchaseId}`, null, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      .then(resp => resp?.data);
  }

  checkDevicePurchase(imei: string, serialNo: string): Promise<PurchaseMessage> {
    return axios
      .get<PurchaseMessage>('/purchases/check-purchase-item', {
        params: { imei: imei, serial_no: serialNo }
      })
      .then(resp => resp.data)
      .catch(error => {
        console.error('Error checking device control status:', error);
        throw error;
      });
  }
}

export const purchasesService = new PurchasesService();
