import axios from '@/core/axios';

import { RmaElement } from './rma-element.types';

export class RmaElementsService {
  list(device_id: number): Promise<RmaElement[]> {
    return axios.post<RmaElement[]>(`/rma-element/list`, { device_id }).then(resp => resp?.data);
  }
  selected(rma_element_id: number): Promise<void> {
    return axios.post<void>(`/rma-element/${rma_element_id}/selected`).then(resp => resp?.data);
  }
  add(rma_element: RmaElement): Promise<RmaElement[]> {
    return axios.post<RmaElement[]>(`/rma-element/add`, { rma_element }).then(resp => resp?.data);
  }
  edit(rma_element: RmaElement): Promise<RmaElement[]> {
    return axios.post<RmaElement[]>(`/rma-element/${rma_element.id}/edit`, { rma_element }).then(resp => resp?.data);
  }
  delete(rma_element_id: number): Promise<RmaElement[]> {
    return axios.delete<RmaElement[]>(`/rma-element/${rma_element_id}/delete`).then(resp => resp?.data);
  }
}

export const rmaElementsService = new RmaElementsService();
