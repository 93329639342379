import axios from '@/core/axios';

import { Unpacking } from './unpacking.types';

export class UnpackingService {
  getUnpackingWithPurchase(id: number): Promise<Unpacking> {
    if (!id) throw new Error('Missing id in getUnpacking');
    return axios.get<Unpacking>(`/unpackings/${id}/with-purchase`).then(resp => resp?.data);
  }
  nextIncrementValue(id: number): Promise<any> {
    if (!id) throw new Error('Missing id in nextIncrementValue');
    return axios.get<any>(`/unpackings/${id}/next-increment-value`).then(resp => resp?.data);
  }
  currentIncrementValue(id: number, increment_value: number): Promise<any> {
    if (!id) throw new Error('Missing id in currentIncrementValue');
    return axios
      .post<any>(`/unpackings/${id}/current-increment-value`, { increment_value: increment_value })
      .then(resp => resp?.data);
  }
}

export const unpackingService = new UnpackingService();
