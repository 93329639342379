import { Module } from 'vuex';

import { gradesService } from '@/services/grades/grades.service';
import { Grade } from '@/services/grades/grades.types';
import { RootState } from '@/store';

export interface GradesState {
  grade: Grade;
  gradeList: Grade[];
  control_fields: string[];
  gradeLoading: boolean;
}

const state: GradesState = {
  grade: null,
  gradeList: [],
  control_fields: [
    'apple_spot',
    'blue_spots',
    'yellow_spots',
    'pink_screen',
    'light_leakage',
    'white_spots',
    'liquid_spots',
    'dust_screen'
  ],
  gradeLoading: false
};

const options: Module<GradesState, RootState> = {
  namespaced: true,
  state: () => state,
  actions: {
    initGrade({ commit }, grade: Grade) {
      commit('grade', grade);
    },
    getGrade({ commit, dispatch }, gradeId: number) {
      commit('gradeLoading', true);
      return gradesService
        .get(gradeId)
        .then(grade => grade && commit('grade', grade))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    getGradeFields({ commit, dispatch }, modelSelected: string) {
      commit('gradeLoading', true);
      return gradesService
        .getGradeFields(modelSelected)
        .then(grade => {
          const formatGrade = ['case', 'screen', 'topcase']
            .map(steps => {
              return grade && grade[steps]
                ? {
                    id: steps,
                    name: steps,
                    childs: Object.keys(grade[steps]['fields']).map(element => {
                      return {
                        id: steps + element,
                        name: element,
                        childs: Object.keys(grade[steps]['fields'][element])
                          ? Object.keys(grade[steps]['fields'][element]).map(child => {
                              let childName = '';
                              if (['on', 'off'].includes(child.split('_')[child.split('_').length - 1])) {
                                childName = child
                                  .split('_')
                                  .splice(0, child.split('_').length - 1)
                                  .join('_');
                              } else childName = child;
                              return element == child
                                ? null
                                : {
                                    id: steps + element + child,
                                    name: childName,
                                    isActive: false,
                                    isChecked: false
                                  };
                            })
                          : null,
                        isActive: false,
                        isChecked: false
                      };
                    }),
                    isActive: false,
                    isChecked: false
                  }
                : null;
            })
            .filter(steps => steps);
          return commit('gradeList', formatGrade);
        })
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    gradeTask: ({ commit, dispatch }, grade: Grade): Promise<void> => {
      commit('gradeLoading', true);
      return gradesService
        .gradeTask(grade)
        .then(grade => grade && commit('grade', grade))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    createGrade: ({ commit, dispatch }, grade: Grade): Promise<void> => {
      commit('gradeLoading', true);
      return gradesService
        .create(grade)
        .then(grade => grade && commit('grade', grade))
        .then(() => dispatch('alert/pushSuccess', 'Grade créée avec succès !', { root: true }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    setGrade: ({ commit, dispatch }, gradeId: number): Promise<void> => {
      commit('gradeLoading', true);
      return gradesService
        .setGrade(gradeId)
        .then(grade => grade && commit('grade', grade))
        .then(() => dispatch('alert/pushSuccess', 'Grade mis à jour avec succès !', { root: true }))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    updateGrade: ({ commit, dispatch, state }, payload: { grading: Grade; step: string }): Promise<void> => {
      commit('gradeLoading', true);
      return gradesService
        .update(state.grade.id, payload)
        .then(grade => grade && commit('grade', grade))
        .then(() => dispatch('device/setDevice', state.grade.serial_no, { root: true }))
        .catch(error => dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    updateOkamacGrade({ commit, dispatch, state }, newGrade: string) {
      commit('gradeLoading', true);
      return gradesService
        .updateOkamacGrade(state.grade.id, newGrade)
        .then(grade => grade && commit('grade', grade))
        .catch(error => error && dispatch('alert/pushError', error, { root: true }))
        .finally(() => commit('gradeLoading', false));
    },
    editStateGrade({ commit }, options: { currentStepCode: string; value }) {
      commit('gradeLoading', true);
      const { currentStepCode, value } = options;
      if (value.fieldLabel)
        state.grade[currentStepCode]['fields'][value.fieldBlockLabel][value.fieldLabel] = value.value;
      else {
        state.grade[currentStepCode]['fields'][value.fieldBlockLabel] = value.value;
      }
      commit('gradeLoading', false);
    },
    saveGradePhoto: ({ commit, dispatch }, gradingPhoto: (File | string)[]): Promise<void> => {
      commit('gradeLoading', true);
      return gradesService
        .deleteGradePhotos(
          state.grade.id,
          gradingPhoto.filter(gp => typeof gp == 'string')
        )
        .then(grade => {
          commit('grade', grade);
          gradingPhoto.forEach((photo: File) => {
            if (typeof photo != 'string')
              gradesService
                .addGradePhotos(grade.id, { gradingPhoto: photo })
                .then(photo => photo && commit('addGradePhotos', photo))
                .catch(error => error && dispatch('alert/pushError', error, { root: true }));
          });
          commit('gradeLoading', false);
        })
        .catch(error => error && dispatch('alert/pushError', error, { root: true }));
    }
  },
  mutations: {
    grade: (state, grade: Grade) => (state.grade = grade),
    gradeList: (state, gradeList: Grade[]) => (state.gradeList = gradeList),
    addGradePhotos: (state, photo: File) => state.grade.grading_photos.push(photo),
    gradeLoading: (state, loading: boolean) => (state.gradeLoading = loading)
  },
  getters: {
    control_fields: state => state.control_fields
  }
};

export default options;
