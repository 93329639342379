import axios from '@/core/axios';
import { SupplierGrade } from './suppliers-grade.types';

export class SupplierGradesService {
  create(supplier_grade: SupplierGrade): Promise<SupplierGrade> {
    return axios.post<SupplierGrade>(`/supplier-grades`, { supplier_grade }).then(resp => resp?.data);
  }
  list(supplier_id: number, model: string): Promise<SupplierGrade[]> {
    return axios.post<SupplierGrade[]>(`/supplier-grades/list`, { supplier_id, model }).then(resp => resp?.data);
  }
  update(id: number, supplier_grade: Partial<SupplierGrade>): Promise<SupplierGrade> {
    if (!id) throw new Error('Missing supplier grade id in update');
    return axios.put<SupplierGrade>(`/supplier-grades/${id}`, { supplier_grade }).then(resp => resp?.data);
  }
}

export const supplierGradesService = new SupplierGradesService();
